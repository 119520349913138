import React from 'react';
import styled from 'styled-components';
import { StatusIcon } from './StatusIcon';
import { MissingData, RiskLevel, ValidationFlags } from '../../../utils/validations/types';
import { HasFlag } from '../../../utils/validations/functions';

const Wrapper = styled.div<{ isCompanyRole: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StatusIcons = ({
  validations,
  entityType,
  sourceType,
}: {
  validations: ValidationFlags;
  entityType: string;
  sourceType?: string;
}) => {
  const { riskLevel, missingData, acceptedHighRisk, acceptedMissingData } = validations;
  return (
    <Wrapper isCompanyRole={entityType === 'CompanyRole'}>
      {sourceType !== 'fleetsList' && riskLevel === RiskLevel.High && !acceptedHighRisk && (
        <span>
          <StatusIcon highRiskIcon text='High Risk - Needs Approval' />
        </span>
      )}
      {sourceType !== 'fleetsList' && riskLevel === RiskLevel.Moderate && (
        <span>
          <StatusIcon moderateRiskIcon text='Moderate Risk' />
        </span>
      )}
      {sourceType !== 'fleetsList' && riskLevel === RiskLevel.High && acceptedHighRisk && (
        <span>
          <StatusIcon acceptedHighRiskIcon text='High Risk - Approved' />
        </span>
      )}
      {(missingData == MissingData.None ||
        (entityType !== 'Company' && (missingData ^ acceptedMissingData) == MissingData.None)) && (
        <span>
          <StatusIcon approvedIcon text='No Missing Information' />
        </span>
      )}
      {HasFlag(missingData ^ acceptedMissingData, MissingData.CompanyData) && (
        <span>
          <StatusIcon missingInfoIcon text='Missing Company Data' />
        </span>
      )}
      {HasFlag(missingData & acceptedMissingData, MissingData.CompanyData) && (
        <span>
          <StatusIcon acceptedMissingInfoIcon text='Missing Company Data - Approved' />
        </span>
      )}
      {HasFlag(missingData ^ acceptedMissingData, MissingData.PersonRoles) && (
        <span>
          <StatusIcon missingPersonRolesIcon text='Missing Person Roles' />
        </span>
      )}
      {HasFlag(missingData & acceptedMissingData, MissingData.PersonRoles) && (
        <span>
          <StatusIcon acceptedMissingRolesIcon text='Missing Person Roles - Approved' />
        </span>
      )}
      {HasFlag(missingData, MissingData.Peti) && (
        <span>
          <StatusIcon missingPetiIcon text='Missing PETI' />
        </span>
      )}
      {HasFlag(missingData, MissingData.BeneficialOwner) && (
        <span>
          <StatusIcon missingBOIcon text='Missing Beneficial Owner' />
        </span>
      )}
      {HasFlag(missingData & ~acceptedMissingData, MissingData.UltimateBeneficialOwner) && (
        <span>
          <StatusIcon missingUboIcon text='Missing Ultimate Beneficial Owner' />
        </span>
      ) }
      {HasFlag(missingData & acceptedMissingData, MissingData.UltimateBeneficialOwner) && (
        <span>
          <StatusIcon acceptedMissingUboIcon text='Missing Ultimate Beneficial Owner - Approved' />
        </span>
      ) }
      {HasFlag(missingData, MissingData.ProofOfRegistration) && (
        <span>
          <StatusIcon missingPorIcon text='Missing Proof of Registration' />
        </span>
      )}
    </Wrapper>
  );
};
