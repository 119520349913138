import React from 'react';
import styled from 'styled-components';
import { postBindTabs } from './fleetTabs';
import { usePostBindFleets } from '../projectTabs/usePostBindFleets';
import { FleetItems } from './FleetItems';
import { Tab } from '../shared/Tab';
import { useAppContext } from 'components/appRouting/AppContext';
import { constants } from 'utils/constants';

const TabWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100px;
`;
const TotalCountWrapper = styled.div`
  font-size: 32px;
`;
export const PostBindTabs = () => {
  const {
    missingInfoFleets,
    followUpFleets,
    overdueFleets,
    noMissingInfoFleets,
    highRiskFleets,
    missingInfoTotalCount,
    overdueTotalCount,
    followUpTotalCount,
    noMissingInfoTotalCount,
    highRiskTotalCount,
    fleetsLimit,
    handleMissingInfoSort,
    handleOverdueSort,
    handleFollowUpSort,
    handleNoMissingInfoSort,
    handleHighRiskSort,
  } = usePostBindFleets();

  const { state, dispatch } = useAppContext();
  const activeTab = state.activeTab;
  const changeTab = tab => dispatch({ type: constants.SET_ACTIVE_TAB, value: tab });
  const overdueCurrentPage = state.currentPage.overdue;
  const setOverdueCurrentPage = (value) => dispatch({
    type: constants.SET_CURRENT_PAGE,
    value: value,
    name: 'overdue'
  });
  const followUpCurrentPage = state.currentPage.followUp;
  const setFollowUpCurrentPage = (value) => dispatch({
    type: constants.SET_CURRENT_PAGE,
    value: value,
    name: 'followUp'
  });
  const missingInfoCurrentPage = state.currentPage.missingInfo;
  const setMissingInfoCurrentPage = (value) => dispatch({
    type: constants.SET_CURRENT_PAGE,
    value: value,
    name: 'missingInfo'
  });
  const noMissingInfoCurrentPage = state.currentPage.noMissingInfo;
  const setNoMissingInfoCurrentPage = (value) => dispatch({
    type: constants.SET_CURRENT_PAGE,
    value: value,
    name: 'noMissingInfo'
  });

  const highRiskCurrentPage = state.currentPage.highRisk;
  const setHighRiskCurrentPage = (value) => dispatch({
    type: constants.SET_CURRENT_PAGE,
    value: value,
    name: 'highRisk'
  });

  return (
    <>
      <TabWrapper>
        {postBindTabs.map((t) => (
          <Tab
            key={t.key}
            onClick={() => changeTab(t.key)}
            selected={activeTab === t.key}
            highlight={t.highlight}
          >
            <TotalCountWrapper>
              {t.key === 'Overdue' && overdueTotalCount}
              {t.key === 'FollowUp' && followUpTotalCount}
              {t.key === 'MissingInfo' && missingInfoTotalCount}
              {t.key === 'NoMissingInfo' && noMissingInfoTotalCount}
              {t.key === 'HighRisk' && highRiskTotalCount}
            </TotalCountWrapper>
            {t.description}
          </Tab>
        ))}
      </TabWrapper>
      {activeTab === 'Overdue' && (
        <FleetItems
          fleets={overdueFleets}
          currentPage={overdueCurrentPage}
          setCurrentPage={setOverdueCurrentPage}
          totalCount={overdueTotalCount}
          pageSize={fleetsLimit}
          handleSort={handleOverdueSort}
          name={'overdue'}
        />
      )}
      {activeTab === 'FollowUp' && (
        <FleetItems
          fleets={followUpFleets}
          currentPage={followUpCurrentPage}
          setCurrentPage={setFollowUpCurrentPage}
          totalCount={followUpTotalCount}
          pageSize={fleetsLimit}
          handleSort={handleFollowUpSort}
          name='followUp'
        />
      )}
      {activeTab === 'MissingInfo' && (
        <FleetItems
          fleets={missingInfoFleets}
          currentPage={missingInfoCurrentPage}
          setCurrentPage={setMissingInfoCurrentPage}
          totalCount={missingInfoTotalCount}
          pageSize={fleetsLimit}
          handleSort={handleMissingInfoSort}
          name={'missingInfo'}
        />
      )}
      {activeTab === 'NoMissingInfo' && (
        <FleetItems
          fleets={noMissingInfoFleets}
          currentPage={noMissingInfoCurrentPage}
          setCurrentPage={setNoMissingInfoCurrentPage}
          totalCount={noMissingInfoTotalCount}
          pageSize={fleetsLimit}
          handleSort={handleNoMissingInfoSort}
          name={'noMissingInfo'}
        />
      )}
      {activeTab === 'HighRisk' && (
        <FleetItems
          fleets={highRiskFleets}
          currentPage={highRiskCurrentPage}
          setCurrentPage={setHighRiskCurrentPage}
          totalCount={highRiskTotalCount}
          pageSize={fleetsLimit}
          handleSort={handleHighRiskSort}
          name={'highRisk'}
          riskLevelFiltering={false}
        />
      )}
    </>
  );
};
