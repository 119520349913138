import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { SelectField } from '../shared/formFields/SelectField';
import { AsyncSelectField } from '../shared/formFields/AsyncSelectField';
import { Label } from '../shared/formFields/Label';
import { useCountries } from '../../services/useCountries';
import { getOrganizations } from '../../services/getOrganizations';
import { OrganizationLabel } from './OrganizationLabel';
import { Formik } from 'formik';
import { CompanyData } from '../company/CompanyData';
import { debounce } from '../../utils/debounce';
import { ImportCompany } from '../../services/postPutCompanyData';
import { newCompanyInitialValues } from '../company/newCompanyInitialValues';
import { Load } from '@instech/icons';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';
import { CompanyProvider } from '../company/CompanyContext';
import { ContentBlock } from 'components/shared/ContentBlock';

const StyledLoad = styled(Load)`
  height: 16px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
  align-items: end;
`;
const NewRoleWrapper = styled.div`
  padding: 0px;
`;
const SearchField = styled.div`
  position: relative;
  display: inline;
`;
const ErrorMessageWrapper = styled.div`
  margin-top: 8px;
  color: red;
`;

const AddNewCompanyButton = ({ sectionTitle, clickNewCompanyHandler }) => (
  <BoxButton
    aria-label='Create company'
    height='30'
    type='button'
    onClick={() => {
      clickNewCompanyHandler();
    }}
  >
    {`CREATE ${sectionTitle.toUpperCase()}`}
  </BoxButton>
);

export const CompanySearch = ({
  fleetId,
  vesselId,
  sectionTitle,
  hideTitle,
  onAdd,
  mutateFleetDetails,
  setIsSearchAssuredOpen,
  setIsSearchCoAssuredOpen,
  covers,
  forMultipleVessels,
  handleStepForward = null,
  currentStep,
  isCoAssured = false,
  organizationsListForChecking,
  handleNewSearch = null,
  setIsCompanyNew = null,
}) => {
  const countries = useCountries();
  const [organization, setOrganization] = useState(null);
  const [isNewCompanyOpen, setIsNewCompanyOpen] = useState(false);
  const [isNewCompanyRoleTypeOpen, setIsNewCompanyRoleTypeOpen] = useState(false);
  const [isNewCompanyForMultipleVessels, setIsNewCompanyForMultipleVessels] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImportError, setIsImportError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const clickNewCompanyHandler = () => {
    setIsNewCompanyOpen((isNewCompanyOpen) => !isNewCompanyOpen);
  };
  const clickNewCompanyForMultipleVesselsHandler = (handleReset) => {
    handleReset();
    setIsNewCompanyForMultipleVessels && setIsNewCompanyForMultipleVessels(true);
    setIsNewCompanyRoleTypeOpen(true);
    handleNewSearch && handleNewSearch();
    setIsCompanyNew && setIsCompanyNew(true);
  };
  const handleNewCompanyRoleType = () => {
    setIsNewCompanyForMultipleVessels && setIsNewCompanyForMultipleVessels(false);
    setIsNewCompanyRoleTypeOpen(true);
  };

  const organizationForCheckingIndex = organizationsListForChecking ? organizationsListForChecking.length : 0;

  const clientRoleType = {
    roleType: {
      id: '',
      roleName: '',
      isCoAssured: isCoAssured,
    },
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        countryCode: '', //change from null to '' to avoid warning 'input value must not be null'
        organizationId: null,
      }}
    >
      {({ values, setFieldValue, setFieldTouched, handleReset }) => {
        const loadOptions = debounce(async (value, callback) => {
          try {
            const orgs = await getOrganizations(values.countryCode, value);
            return callback(
              orgs.map((org) => {
                return {
                  value: org.id + '/' + org.sourceSystem + '/' + org.sourceId,
                  label: org.name,
                  organization: org,
                };
              })
            );
          } catch (error) {
            console.log(error);
          }
        }, 500);

        return (
          <ContentBlock title={hideTitle ? undefined : `Add ${sectionTitle}`} type='secondary'>
            <Row>
              <div>
                <Label label='Org.number/LEI Code/Company Name' />
                <SearchField>
                  <AsyncSelectField
                    name='organizationId'
                    placeholder='Search by org.number, LEI code, or Company Name'
                    loadOptions={loadOptions}
                    formatOptionLabel={OrganizationLabel}
                    handleChange={(_, value) => {
                      setIsCompanyNew && setIsCompanyNew(false);
                      handleNewSearch && handleNewSearch();
                      setFieldValue(_, value || '');
                      setFieldValue('organizationId', '');
                      setIsNewCompanyRoleTypeOpen(false);
                      setIsLoading(true);
                      setIsImportError(false);
                      setErrorMessage(null);
                      if (
                        (value.organization.sourceSystem.toUpperCase() === 'GLEIF' ||
                          value.organization.sourceSystem.toUpperCase() === 'CUSTOMERFORM') &&
                        value.organization.id === '00000000-0000-0000-0000-000000000000'
                      ) {
                        ImportCompany({
                          sourceId: value.organization.sourceId,
                          sourceSystem: value.organization.sourceSystem,
                          twoLetterCountryCode: value.organization.country.partitionKey,
                        })
                          .then((response) => {
                            setOrganization({
                              ...response,
                              //partitionKey: response.legalAddress.country.partitionKey, //????
                            });
                            handleNewCompanyRoleType();
                            setIsLoading(false);
                          })
                          .catch((error) => {
                            console.log(error);
                            setIsImportError(true);
                            setErrorMessage(formatErrorMessage(error));
                            setIsLoading(false);
                          });
                      } else {
                        setOrganization({
                          ...value.organization,
                          //partitionKey: value.organization.country.partitionKey, // ????
                        });
                        handleNewCompanyRoleType();
                        setIsLoading(false);
                      }
                    }}
                    handleBlur={setFieldTouched}
                  />
                </SearchField>
              </div>
              <div>
                <Label label='Country' />
                <SelectField
                  name='countryCode'
                  placeholder='Not selected'
                  options={countries}
                  displayNameSelector={(x) => x.name}
                  valueNameSelector={(x) => x.twoLetterCountryCode}
                  handleChange={(name, value) => {
                    setIsCompanyNew && setIsCompanyNew(false);
                    handleNewSearch && handleNewSearch();
                    setFieldValue(name, value || '');
                    setFieldValue('organizationId', '');
                  }}
                  handleBlur={setFieldTouched}
                  value={values.countryCode || ''}
                />
              </div>
              {!forMultipleVessels && (
                <Link
                  to={{
                    pathname: `/newcompany/${fleetId}/${vesselId}/${sectionTitle}`,
                  }}
                >
                  <AddNewCompanyButton sectionTitle={sectionTitle} clickNewCompanyHandler={clickNewCompanyHandler} />
                </Link>
              )}
              {forMultipleVessels && (
                <AddNewCompanyButton
                  sectionTitle={sectionTitle}
                  clickNewCompanyHandler={() => clickNewCompanyForMultipleVesselsHandler(handleReset)}
                />
              )}
              {isNewCompanyOpen && (
                <CompanyProvider fleetId={fleetId} client='' clientRoleType='' activePeriod=''>
                  <CompanyData />
                </CompanyProvider>
              )}
              {isLoading && <StyledLoad />}
            </Row>
            <NewRoleWrapper>
              {isNewCompanyRoleTypeOpen && (
                <CompanyProvider
                  clientRoleType={clientRoleType}
                  fleetId={fleetId}
                  vesselId={vesselId}
                  client={!isNewCompanyForMultipleVessels ? organization : null}
                  isClientFromSearch={true}
                  newCompanyOrganization={isNewCompanyForMultipleVessels ? newCompanyInitialValues : null}
                  isCoAssured={isCoAssured}
                  mutateFleetDetails={mutateFleetDetails}
                  mutateVesselList={onAdd}
                  isNewRoleTypeCompany={true}
                  setIsNewCompanyRoleTypeOpen={setIsNewCompanyRoleTypeOpen}
                  setIsSearchAssuredOpen={setIsSearchAssuredOpen}
                  setIsSearchCoAssuredOpen={setIsSearchCoAssuredOpen}
                  organizationForCheckingIndex={organizationForCheckingIndex}
                  covers={covers}
                  forMultipleVessels={forMultipleVessels}
                  handleStepForward={handleStepForward}
                  currentStep={currentStep}
                  isRoleTypeEditable={true}
                  organizationsListForChecking={organizationsListForChecking}
                >
                  <CompanyData />
                </CompanyProvider>
              )}
            </NewRoleWrapper>
            {isImportError && (
              <ErrorMessageWrapper>
                <div>Failed to import company from an external source...</div>
                <div>{errorMessage}</div>
              </ErrorMessageWrapper>
            )}
          </ContentBlock>
        );
      }}
    </Formik>
  );
};

CompanySearch.propTypes = {
  fleetId: PropTypes.string,
  vesselId: PropTypes.string,
  onAdd: PropTypes.func,
};
