import { putJsonAsync, postJsonAsync } from './fetchConfig';
import useSWR from 'swr';

//get person
export const usePersonById = (personId) => {
    const { data, mutate } = useSWR(`persons/${personId}`);
    return { personData: data, mutate }
};

//create person
export const postNewPerson = (values, etag) => postJsonAsync('persons', values, { headers: { 'If-Match': etag } });

//update person
export const putPerson = (personId, values, etag) => putJsonAsync(`persons/${personId}`, values, { headers: { 'If-Match': etag } });

//search person
export const getPersonRoles = (person) => postJsonAsync('Persons/search', person);

//create person role for organization or insured object
export const postNewRole = (params, etag) => {
  const { isUbo, organizationId, fleetId, objectId, personId, roleType, activePeriod } = params;
  const payload = { data: { personId, roleType, activePeriod, etag } }
  const url = isUbo ? `fleets/${fleetId}/insured-objects/${objectId}/personRoles` : `/organizations/${organizationId}/personRoles`;
  if (isUbo) {
    payload.data.fleetId = fleetId;
    payload.data.insuredObjectId = objectId;
  } else {
    payload.data.organizationId = organizationId;
  }
  return postJsonAsync(url, payload, { headers: { 'If-Match': etag } })
};

//Update person role for organization or insured object
export const putRole = (params, etag) => {
  const { isUbo, organizationId, fleetId, objectId, personId, roleType, activePeriod, personRoleId } = params;
  const payload = { data: { personId, personRoleId, roleType, activePeriod, etag } }
  const url = isUbo ? `fleets/${fleetId}/insured-objects/${objectId}/personRoles/${personRoleId}` : `/organizations/${organizationId}/personRoles/${personRoleId}`;
  if (isUbo) {
    payload.data.fleetId = fleetId;
    payload.data.insuredObjectId = objectId;
  } else {
    payload.data.organizationId = organizationId;
  }
  return putJsonAsync(url, payload, { headers: { 'If-Match': etag } })
};

//Delete person role for organization or insured object
export const deletePersonRole = (params, etag) => {
  const { isUbo, organizationId, fleetId, objectId, personRoleId } = params;
  const payload = { data: { personRoleId, etag } }
  const url = isUbo 
  ? `fleets/${fleetId}/insured-objects/${objectId}/personRoles/${personRoleId}/delete` 
  : `/organizations/${organizationId}/personRoles/${personRoleId}/delete`;
  if (isUbo) {
      payload.data.fleetId = fleetId;
      payload.data.insuredObjectId = objectId;
  } else {
      payload.data.organizationId = organizationId;
  }
  return postJsonAsync(url, payload, { headers: { 'If-Match': etag } })
};
