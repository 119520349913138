import useSWR from 'swr';
import { getPersonRoles } from './postPutPersonData';
import { formatDateOfBirth } from '../utils/date';

export const usePersonsList = (shouldFetch, params) => {
    const endpoint = `persons`;
    let hasMore = false;
    
    const searchData = {...params, dateOfBirth: formatDateOfBirth(params?.dateOfBirth)};
    
    const { data, mutate } = useSWR(shouldFetch ? endpoint : null, () => getPersonRoles(searchData));

  return { persons: data, mutate, hasMore }
};
