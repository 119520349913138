import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: scroll;
`;

const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 30%;
  right: 30%;
  top: 50px;
  height: fit-content;
  margin: auto;
  background: white;
  padding: 0;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0 16px;
`;

const CloseButton = styled.button`
  margin: 5px;
  align-self: flex-end;
  justify-self: flex-end;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
  color: #de350b;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  transition: all 0.2s ease;

  :hover {
    background-color: #ffbdad;
    transform: scale(1.3);
  }
`;


type ModalProps = {
  title: ReactNode;
  children: ReactNode;
  onClose: () => void;
}

export const Modal = ({ title, children, onClose }: ModalProps) => {
  return (
    <Wrapper>
      <Dialog>
        <CloseButton type='button' onClick={onClose}>
          x
        </CloseButton>
        <Title>{title}</Title>
        {children}
      </Dialog>
    </Wrapper>
  );
};
