import React from 'react';
import styled from 'styled-components';
import { KycLogo } from '@instech/icons';
import { Link } from 'react-router-dom';
import { UserSection } from './UserSection';
import { HeaderTabs } from './HeaderTabs';
import { useAppContext } from '../appRouting/AppContext';
import { PortalSelectorLoader } from './PortalSelectorLoader';

const LogoLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${(props) => props.theme.white};
`;
const HeaderRow = styled.div`
  height: 70px;
  padding: 0 18px 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.marineBlue};
  color: ${(props) => props.theme.white};
`;

const LogoText = styled.span`
  white-space: nowrap;
  font-size: 20px;
  font-weight: bold;
  margin-left: 8px;
  margin-top: 4px;
  font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
  :before {
    content: 'KYC';
  }
  @media (min-width: 1050px) {
    :before {
      content: 'Know Your Customer';
    }
  }
`;
const MenuSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const HeaderLogo = () => (
  <LogoLink to="/">
    <KycLogo />
    <LogoText></LogoText>
  </LogoLink>
);

const UserName = ({ isAuthenticated }) => (isAuthenticated ? <UserSection /> : <div></div>);

export const Header = () => {
  const { isAuthenticated, singleCompanyId } = useAppContext();

  return (
    <HeaderRow>
      <HeaderLogo />
      <HeaderTabs singleCompanyId={singleCompanyId} isAuthenticated={isAuthenticated} />
      <MenuSection>
        <UserName isAuthenticated={isAuthenticated} />
        <PortalSelectorLoader />
      </MenuSection>
    </HeaderRow>
  );
};
