import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate, getFriendlyDateTime } from '../../utils/date';
import { getUser } from '../../services/getUser';
import { getOrganization } from '../../services/getOrganization';
import { createExternalId } from '../../utils/createExternalId';
import { getAcceptanceLogComments } from "../../utils/acceptanceLogUtils";

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 2fr) repeat(7, minmax(80px, 1fr)) minmax(150px, 2fr);
  grid-gap: 8px;
  align-items: center;
  padding: 16px 8px;
  word-wrap: break-word;
  :nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

export const LogDataOrganization = ({ item }) => {
  const [organization, setOrganization] = useState({});
  const [user, setUser] = useState({});
  const [externalId, setExternalId] = useState('');

  useEffect(() => {
    item.organizationId &&
      getOrganization(item.organizationId).then((response) => {
        setOrganization(response);
        setExternalId(createExternalId(response));
      });
    item.userId &&
      getUser(item.userId).then((response) => {
        setUser(response);
      });
  }, [item.userId, item.organizationId]);

  return (
    <Grid>
      <span>{organization?.name}</span>
      <span>{externalId}</span>
      <span></span>
      <span></span>
      <span>{getFriendlyDate(item.acceptancePeriod.from)}</span>
      <span>{getFriendlyDate(item.acceptancePeriod.to)}</span>
      <span>{user.name}</span>
      <span>{getFriendlyDateTime(item.acceptedAt)}</span>
      <span>{getAcceptanceLogComments(item)}</span>
    </Grid>
  );
};

LogDataOrganization.propTypes = {
  item: PropTypes.object,
};
