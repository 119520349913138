import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { CompanyRoleSection } from './CompanyRoleSection';
import { NewPersonForm } from './NewPersonForm';
import { removePersonRole } from './managePersonRoles';
import { LabelWithIcon } from '../shared/LabelWithIcon';
import { ContentBlock } from '../shared/ContentBlock';
import { BoxButton, CheckboxControlled } from '@instech/components';
import { useCompanyContext } from '../company/CompanyContext';
import { getPersonNotification } from '../company/helpers';
import { Add, Load } from '@instech/icons';
import { AcceptMissingRole } from '../company/AcceptMissingRole';
import { MarkExecutiveRolesComplete } from '../../services/postPutCompanyData';
import { AcceptMissingUbo } from "../company/AcceptMissingUbo";
import { getFriendlyDate } from "../../utils/date";

const StyledLoad = styled(Load)`
  height: 20px;
`;
const TableWrapper = styled.div`
  margin-top: 16px;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.marineBlue};
`;
const RolesHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(5, 1fr) 0.5fr 50px;
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue50};
  font-weight: bold;
  font-size: 14px;
`;
const AddRoleButton = styled.div<{ isWarning?: string | false }>`
  margin-top: ${(props) => (props.isWarning ? '16px' : '0')};
  button {
    border: none;
    box-shadow: none;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;
const WarningWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  font-weight: normal;
  font-weight: bold;

  .acceptMissingInfoButton {
    background-color: ${(props) => props.theme.yellow};
    color: ${(props) => props.theme.marineBlue};
    border: none;
    :hover {
      color: ${(props) => props.theme.white};
    }
  }
`;
const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
`;

type activePeriodProps = {
    from: string;
    to: string;
}

type PersonReferenceProps = {
    id: string;
}

type rolesProps = {
    activePeriod?: activePeriodProps;
    id?: string;
    isActive: boolean;
    isDeleted: boolean;
    personReference?: PersonReferenceProps;
}

type valuesProps = {
  roles: rolesProps[];
  acceptedMissingPersonRoles: boolean;
  id?: null;
  _etag: string;
}

type vesselContextProps = {
  isMissingUbo: boolean;
}

type locationStateProps = {
    clientId: string;
    fleetId: string;
    vesselId: string;
}

type validationsProps = {
    acceptedHighRisk: boolean;
    acceptedMissingPersonRoles: number;
    missingData: number;
    riskLevel: number;
}

interface PersonRolesProps {
    isNewPersonFormOpen: boolean;
    openNewPersonForm: () => void;
    mutateVesselList: () => void;
    values: valuesProps;
    vesselContext: vesselContextProps;
    fleetId: string;
    vesselId: string;
    title: string;
}

export const PersonRoles = ({
  isNewPersonFormOpen,
  openNewPersonForm,
  mutateVesselList,
  values,
  vesselContext,
  fleetId,
  vesselId,
  title,
}: PersonRolesProps) => {

  const { forMultipleVessels, isNewCompany, mutate, locationState, validations, organization } = useCompanyContext() as {
    forMultipleVessels?: any;
    isNewCompany?: any;
    mutate: () => void;
    locationState: locationStateProps;
    validations: validationsProps;
    organization?: any;
    };

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const clickNewPersonFormHandler = () => {
    openNewPersonForm();
  };

  const isUbo = !!vesselContext;
  const missingRoleNotification = validations
    ? getPersonNotification(values, forMultipleVessels, isNewCompany, validations, organization.executiveRolesMarkedComplete)
    : false;
  const isWarning = vesselContext?.isMissingUbo || (!!missingRoleNotification && !values.acceptedMissingPersonRoles);

  return (
    <FieldArray name="roles">
      {({ remove }) => {
        return (
          <ContentBlock title={title} type={isWarning ? 'warning' : 'default'}>
            {!!missingRoleNotification && !values.acceptedMissingPersonRoles && (
              <WarningWrapper>
                <AcceptMissingRole />
                <WarningMessage>
                  {'  !  '}
                  {missingRoleNotification}
                </WarningMessage>
              </WarningWrapper>
            )}
            {vesselContext?.isMissingUbo && (
              <WarningWrapper>
                <AcceptMissingUbo insuredObjectId={vesselId} fleetId={fleetId} />
              </WarningWrapper>
            )}
            <ButtonWrapper>
              {!forMultipleVessels && !isNewCompany && !isNewPersonFormOpen && (
                <AddRoleButton isWarning={missingRoleNotification}>
                  <BoxButton
                    data-test-id="add-person"
                    aria-label="Add Person"
                    onClick={() => {
                      clickNewPersonFormHandler();
                    }}
                    startIcon={<Add />}
                    type="button"
                    padding="0"
                    inverted
                  >
                    {isUbo ? 'Add UBO' : 'Add Person'}
                  </BoxButton>
                </AddRoleButton>
              )}
              {showLoader && <StyledLoad />}
            </ButtonWrapper>
            {isNewPersonFormOpen && (
              <NewPersonForm
                onSubmit={() => {
                  mutate && mutate();
                  mutateVesselList && mutateVesselList();
                }}
                openNewPersonForm={openNewPersonForm}
                clientId={values.id}
                etag={values._etag}
                isUbo={isUbo}
                fleetId={fleetId}
                vesselId={vesselId}
              />
            )}
            {!forMultipleVessels && !isNewCompany && values.roles.length > 0 && (
              <TableWrapper>
                {values.roles.length > 0 && (
                  <RolesHeader>
                    <div>Role</div>
                    <div>In Role</div>
                    <div>Nationality</div>
                    <div>First Name</div>
                    <div>Last Name</div>
                    <div>Date of Birth</div>
                    <LabelWithIcon labelText="PEP" infoText="Politically Exposed Person" questionIcon />
                    <div></div>
                  </RolesHeader>
                )}
                <div>
                  {!forMultipleVessels &&
                    values.roles.length > 0 &&
                    values.roles.map((role, index) => {
                      return (
                        <CompanyRoleSection
                          key={index}
                          remove={remove}
                          index={index}
                          values={role}
                          clientId={values.id}
                          fleetId={fleetId}
                          vesselId={vesselId}
                          isUbo={isUbo}
                          handleRemove={async () => {
                            await removePersonRole(
                              index,
                              remove,
                              role.id,
                              values._etag,
                              isUbo,
                              fleetId,
                              vesselId,
                              values.id,
                              setShowLoader,
                              null,
                              mutate,
                              mutateVesselList,
                              null
                            ).then(() => {
                              mutate();
                            });
                          }}
                          locationState={locationState || { clientId: values.id }}
                        />
                      );
                    })}
                </div>
              </TableWrapper>
            )}
            {organization && values.roles.length > 0 && (
              <CheckboxControlled name={"executiveRolesCompleted"} selected={organization.executiveRolesMarkedComplete}
                                  rightLabel={"I confirm that all persons who exercise control and influence over the company have been listed."}
                                  onChange={async () => {
                          setShowLoader(true);
                          
                          await MarkExecutiveRolesComplete(
                              organization.id,
                              !organization.executiveRolesMarkedComplete,
                              organization._etag)
                              .then(result => {
                                if (result) {
                                  mutate();
                                }
                              })
                              .finally(() => {
                                setShowLoader(false);
                              });
              }}/>
            )}
            {!isUbo && organization?.rolesLastUpToDate && (
                <div>
                  Roles last up to date:&nbsp;
                  <span style={{ color:'black' }}>
                    { getFriendlyDate(organization.rolesLastUpToDate) }
                  </span>
                </div>
              )}
          </ContentBlock>
        );
      }}
    </FieldArray>
  );
};
