import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckboxField = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  & label {
    display: flex;
    align-items: center;
    line-height: 21px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    // This is needed to make the text truncation work in the children
    overflow: hidden;
  }
  & input[type='checkbox']:disabled {
    cursor: default;
  }
  & input[type='checkbox'] {
    position: relative;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    line-height: 21px;
    box-sizing: content-box;
    appearance: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    &::before {
      content: '';
      display: inline-block;
      left: 0px;
      width: 20px;
      height: 20px;
      background: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      border: 1px solid
        ${(props) =>
          props.error ? props.theme.red : props.disabled ? props.theme.mediumGray : props.theme.border.gray};
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
    }
    &:checked::before {
      border-color: ${(props) => props.theme.marineBlue};
      background: ${(props) => props.theme.marineBlue};
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0px;
      height: 0px;
      bottom: 5px;
      left: 7px;
      border: 1px solid transparent;
      border-bottom-color: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      border-right-color: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      transform: rotate(45deg);
    }
    &:checked::after {
      width: 5px;
      height: 13px;
    }
  }
  span {
    color: ${(props) => props.theme.white};
    position: absolute;
    bottom: 16px;
    left: -4px;
    padding: 5px 7px;
    z-index: -1;
    font-size: 14px;
    border-radius: 2px;
    background: ${(props) => props.theme.marineBlue};
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 999;
    width: fit-content;
    white-space: nowrap;
  }
  span:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${(props) => props.theme.marineBlue};
    position: absolute;
    bottom: -8px;
  }
  :hover span {
    bottom: 24px;
    visibility: visible;
    opacity: 1;
    width: fit-content;
    white-space: nowrap;
  }
`;

export const Checkbox = ({ name, value, label, infoText, checked, onChange, disabled }) => {
  return (
    <CheckboxField disabled={disabled}>
      <label>
        <input name={name} type='checkbox' checked={checked} onChange={onChange} value={value} disabled={disabled} />
        {label}
      </label>
      {disabled && <span>{infoText}</span>}
    </CheckboxField>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
