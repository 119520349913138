import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LeftArrow, Search, Home, Load } from '@instech/icons';
import { getAllCountries, getHighRiskCountries, updateHighRiskCountry } from '../../services/getCountries';
import { CountryItem } from './CountryItem';
import { ApproveChanges } from './ApproveChanges';
import { BackgroundTaskProgressBar } from '../shared/BackgroundTaskProgressBar';
import { areArraysDifferent } from '../../utils/areArraysDifferent';
import { useCountryValidationTasks } from '../../services/useValidationTasks';
import { WarningNotification } from '@instech/components';
import { useAppContext } from '../appRouting/AppContext';

const StyledSearch = styled(Search)`
  position: relative;
  left: -50px;
  top: 5px;
`;
const StyledLoad = styled(Load)`
  height: 10px;
  margin: 16px;
`;
const StyledHome = styled(Home)`
  margin-right: 4px;
`;
const ErrorMessage = styled.div`
  color: red;
  margin-top: 8px;
  text-align: right;
`;
const Wrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
  margin: 32px 64px;
`;
const StyledLink = styled(Link)`
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  :visited {
    color: ${(props) => props.theme.marineBlue};
  }
`;
const Description = styled.div``;
const Content = styled.div`
  margin: 0 64px;
`;
const CountriesContainer = styled.div`
  background-color: ${(props) => props.theme.lightBlue};
  margin: 16px 0;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
`;
const Title = styled.h2``;
const Subtitle = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.whiteBlue};
  padding: 10px 16px;
  font-weight: bold;
`;
const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
`;

const StyledInput = styled.input`
  color: ${(props) => props.theme.marineBlue};
  font-size: 16px;
  margin: 16px 24px 0 24px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.marineBlue50};
  border-radius: 2px;
  min-width: 220px;
  outline: none;
  :focus {
    border: 1px solid ${(props) => props.theme.marineBlue};
  }
  ::placeholder {
    color: ${(props) => props.theme.marineBlue50};
  }
`;

export const ManageHighRiskCountries = () => {
  const { isUserRoleAllowed } = useAppContext();
  const [approveChanges, setApproveChanges] = useState(false);
  const [openCountryMenuId, setOpenCountryMenuId] = useState('');
  const [redBackground, setRedBackground] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [changedStatusIds, setChangedStatusIds] = useState([]);
  const [shouldFetchCountryValidationTasks, setShouldFetchCountryValidationTasks] = useState(false);

  const { data: countryValidationTasks, mutate: mutateCountryValidationTasks } = useCountryValidationTasks(
    shouldFetchCountryValidationTasks
  );

  useEffect(() => {
    isUserRoleAllowed && setShouldFetchCountryValidationTasks(true);
  }, [isUserRoleAllowed]);

  const clickOpenCountryMenu = (id) => {
    setOpenCountryMenuId(id);
  };

  const [name, setName] = useState('');
  const [allCountries, setAllCountries] = useState(null);
  const [notHighRiskCountries, setNotHighRiskCountries] = useState(null);
  const [highRiskCountries, setHighRiskCountries] = useState(null);
  const [initialData, setInitialData] = useState({
    highRiskCountries,
    notHighRiskCountries,
  });
  const filtered =
    !!name &&
    notHighRiskCountries &&
    notHighRiskCountries?.filter((dt) => dt.name?.toLowerCase().includes(name.toLowerCase()));

  useEffect(() => {
    const loadCountries = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const allCountriesResult = await getAllCountries();
        const highRiskCountriesResult = await getHighRiskCountries();
        const highRiskCountriesFullData = allCountriesResult?.filter((country) =>
          highRiskCountriesResult.some((item) => item.value === country.twoLetterCountryCode)
        );
        const notHighRiskCountriesResult = allCountriesResult?.filter(
          (country) => !highRiskCountriesFullData.some((item) => item.id === country.id)
        );
        setIsLoading(false);
        setAllCountries(allCountriesResult);
        !notHighRiskCountries && setNotHighRiskCountries(notHighRiskCountriesResult);
        !highRiskCountries && setHighRiskCountries(highRiskCountriesFullData);
        setInitialData({
          highRiskCountries: highRiskCountriesFullData,
          notHighRiskCountries: notHighRiskCountriesResult,
        });
      } catch (e) {
        console.log(e);
      }
    };
    loadCountries();
  }, [isApproved]);

  const handleChange = (country, isHighRiskCountry = false) => {
    setIsError(false);
    setIsApproved(false);
    setApproveChanges(true);
    setRedBackground(true);
    setTimeout(() => {
      if (isHighRiskCountry) {
        if (initialData.highRiskCountries.some((c) => c.id === country.id)) {
          setChangedStatusIds((prev) => [...prev, country.id]);
        } else {
          setChangedStatusIds((prev) => prev && prev.length > 0 && prev.filter((p) => p !== country.id));
        }
        setHighRiskCountries((prev) => prev && prev.length > 0 && prev.filter((c) => c.id !== country.id));
        setNotHighRiskCountries((prev) =>
          [...prev, country].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        );
      } else {
        if (initialData.notHighRiskCountries.some((c) => c.id === country.id)) {
          setChangedStatusIds((prev) => [...prev, country.id]);
        } else {
          setChangedStatusIds((prev) => prev && prev.length > 0 && prev.filter((p) => p !== country.id));
        }
        setNotHighRiskCountries((prev) => prev && prev.length > 0 && prev.filter((c) => c.id !== country.id));
        setHighRiskCountries((prev) =>
          [...prev, country].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        );
      }
      setOpenCountryMenuId('');
    }, 100);
    setOpenCountryMenuId('');
  };

  const node = useRef(null);
  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setOpenCountryMenuId('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (
      highRiskCountries &&
      initialData.highRiskCountries &&
      areArraysDifferent(initialData.highRiskCountries, highRiskCountries, 'id')
    ) {
      setApproveChanges(true);
    } else {
      setApproveChanges(false);
    }
  }, [highRiskCountries, initialData]);

  const handleApprove = () => {
    setIsError(false);
    const highRiskCountryCodes = highRiskCountries?.map((c) => c.twoLetterCountryCode);
    setShouldFetchCountryValidationTasks(true);
    mutateCountryValidationTasks();
    setIsApproved(true);
    setRedBackground(false);
    setChangedStatusIds([]);
    updateHighRiskCountry({ countryCodes: highRiskCountryCodes })
      .then(() => {
        mutateCountryValidationTasks();
        setIsApproved(true);
        setRedBackground(false);
        setChangedStatusIds([]);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
        setRedBackground(false);
      });
  };

  const handleCancel = () => {
    setIsError(false);
    setHighRiskCountries(initialData.highRiskCountries);
    setNotHighRiskCountries(initialData.notHighRiskCountries);
    setRedBackground(false);
    setChangedStatusIds([]);
  };
  const hasCountryTask = (id) => countryValidationTasks && countryValidationTasks.some((t) => t.data.entityId === id);
  const getCountryName = (id) => allCountries && allCountries.filter((c) => c.id === id)[0].name;
  return (
    <Wrapper>
      <StyledLink data-test-id="link-to-main-page" to="/">
        <LeftArrow />
        <div>
          <StyledHome />
          <span>Home</span>
        </div>
      </StyledLink>
      <Content>
        <Title>High Risk Countries {isUserRoleAllowed && 'Management'}</Title>
        <Description>View {isUserRoleAllowed && 'and manage'} countries that are HIGH RISK</Description>
        {!isApproved && approveChanges && (
          <ApproveChanges handleApprove={handleApprove} handleCancel={handleCancel} isError={isError} />
        )}
        {isApproved && !isError && countryValidationTasks && countryValidationTasks.length > 0 && (
          <WarningNotification
            size="small"
            headingText="It will take some time to update high risk flags in the system. Updated items will be available to edit again in about  25-30min."
          />
        )}
        {isError && <ErrorMessage>Failed to update High Risk countries list</ErrorMessage>}
        {countryValidationTasks &&
          countryValidationTasks.length > 0 &&
          countryValidationTasks.map((task, index) => (
            <BackgroundTaskProgressBar
              key={index}
              eventStreamTopic={task}
              titleText={getCountryName(task.data.commandSubject.id)}
              mutate={mutateCountryValidationTasks}
              eventName="Country"
            />
          ))}
        <CountriesContainer>
          <Subtitle>HIGH RISK Countries</Subtitle>
          {isLoading && !approveChanges && <StyledLoad />}
          <ItemsContainer>
            {highRiskCountries?.map((el) => {
              const isStatusChanged = changedStatusIds && changedStatusIds.includes(el.id);

              return (
                <CountryItem
                  key={el.id}
                  country={el}
                  clickOpenCountryMenu={clickOpenCountryMenu}
                  openCountryMenuId={openCountryMenuId}
                  setOpenCountryMenuId={setOpenCountryMenuId}
                  handleChange={() => handleChange(el, true)}
                  isHighRiskCountry={true}
                  node={node}
                  redBackground={redBackground}
                  isStatusChanged={isStatusChanged}
                  disabled={hasCountryTask(el.id)}
                  isUserRoleAllowed={isUserRoleAllowed}
                />
              );
            })}
          </ItemsContainer>
        </CountriesContainer>
        <CountriesContainer>
          <Subtitle>Add HIGH RISK Country</Subtitle>
          <div>
            <StyledInput type="text" placeholder="Search..." value={name} onChange={(e) => setName(e.target.value)} />
            <StyledSearch />
          </div>
          {isLoading && !approveChanges && <StyledLoad />}
          <ItemsContainer>
            {!name &&
              notHighRiskCountries?.map((el) => {
                const isStatusChanged = changedStatusIds && changedStatusIds.includes(el.id);
                return (
                  <CountryItem
                    key={el.id}
                    country={el}
                    clickOpenCountryMenu={clickOpenCountryMenu}
                    openCountryMenuId={openCountryMenuId}
                    setOpenCountryMenuId={setOpenCountryMenuId}
                    handleChange={() => handleChange(el)}
                    node={node}
                    redBackground={redBackground}
                    isStatusChanged={isStatusChanged}
                    disabled={hasCountryTask(el.id)}
                    isUserRoleAllowed={isUserRoleAllowed}
                  />
                );
              })}
            {filtered &&
              filtered.map((el) => {
                return (
                  <CountryItem
                    key={el.id}
                    country={el}
                    clickOpenCountryMenu={clickOpenCountryMenu}
                    openCountryMenuId={openCountryMenuId}
                    setOpenCountryMenuId={setOpenCountryMenuId}
                    handleChange={() => handleChange(el)}
                    node={node}
                    isUserRoleAllowed={isUserRoleAllowed}
                  />
                );
              })}
          </ItemsContainer>
        </CountriesContainer>
      </Content>
    </Wrapper>
  );
};
