import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useFleetOverviewContext } from './FleetOverviewContext';
import { ContentBlock } from '../shared/ContentBlock';
import { ManualHighRiskForFleet, useManualHighRisksForFleet } from 'services/useManualHighRisks';
import { PagedContent } from 'components/shared/PagedContent';
import { getFriendlyDateTime } from 'utils/date';
import { useUser } from 'services/useUser';
import { Loader } from '@instech/components';
import { useRiskLevelAcceptanceLogForFleet } from "../../services/useAcceptanceLogs";

const Table = styled.div`
  margin-bottom: 10px;
  background: ${(props) => props.theme.white};
  padding-bottom: 16px;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 3fr;
  color: ${(props) => props.theme.marineBlue};
  border-bottom: 1px solid ${(props) => props.theme.marineBlue50};
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 3fr;
  text-align: center;
  align-items: center;
  padding: 10px;
  line-height: 24px;

  :nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

const PlaceholderDataRow = styled.div`
  line-height: 24px;
  padding: 10px;
  text-align: center;
`;

const StyledLoader = styled(Loader)`
  height: 16px;
`;

type ActivityLogData = {
  id: string,
  type: string,
  createdDateTime: string,
  userId: string,
  comments: string
}

const ActivityLogItem = ({ data }: { data: ActivityLogData }) => {
  const user = useUser(data.userId) as { name?: string } | undefined;
  return (
    <TableRow>
      <span>{data.type}</span>
      <span>{getFriendlyDateTime(data.createdDateTime)}</span>
      <span>{user?.name}</span>
      <span>{data.comments}</span>
    </TableRow>
  );
}

export const FleetActivityLog = () => {
  const { fleetId } = useFleetOverviewContext() as { fleetId: string; };
  const {
    data: manualHighRisks,
    isValidating: isManualHighRisksLoading
  } = useManualHighRisksForFleet(fleetId);
  const {
    data: acceptanceLogs,
    isValidating: isAcceptanceLogsLoading
  } = useRiskLevelAcceptanceLogForFleet(fleetId);

  const isLoading = isManualHighRisksLoading || isAcceptanceLogsLoading;

  const activityLogData = useMemo(() => {
    const manualHighRiskLogItems: ActivityLogData[] =
      manualHighRisks?.map((manualHighRisk) => ({
        type: "Set to high risk",
        createdDateTime: manualHighRisk.createdAt,
        ...manualHighRisk
      })) ?? [];

    const acceptanceLogItems: ActivityLogData[] =
      acceptanceLogs?.filter(a => a.entityType === 'ManualHighRiskForFleet')
        .map((acceptanceLog) => ({
          type: `High risk review ${acceptanceLog.acceptanceReviewStatus}`,
          createdDateTime: acceptanceLog.acceptedAt,
          ...acceptanceLog
        })) ?? [];

    return [...manualHighRiskLogItems, ...acceptanceLogItems]
      .sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime());
  }, [manualHighRisks, acceptanceLogs]);

  return (
    <ContentBlock title="Activity Log">
      <Table>
      <TableHeader>
        <span>Log Type</span>
        <span>Created Date/Time</span>
        <span>Created by</span>
        <span>Comments</span>
      </TableHeader>
      {isLoading && <PlaceholderDataRow><StyledLoader /></PlaceholderDataRow>}
      <PagedContent items={activityLogData} itemsPerPage={8}>
        {(itemsForPage) => itemsForPage.length || isLoading
          ? itemsForPage.map((item) => <ActivityLogItem key={item.id} data={item}/>)
          : <PlaceholderDataRow>There is no data available</PlaceholderDataRow>}
      </PagedContent>
    </Table>
    </ContentBlock>
  )
};
