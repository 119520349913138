import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import { FeatureFlagProvider } from './providers/featureFlagProvider';
import { theme } from './utils/theme';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { getJsonAsync } from './services/fetchConfig';
import { AuthenticationProvider } from './providers/authenticationProvider';

const swrConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: getJsonAsync,
  onError: (error: any) => {
    if (error.response?.status === 403) {
      window.location.href = 'user/unauthorized';
    }
  },
};

ReactDOM.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <ThemeProvider theme={theme}>
        <SWRConfig value={swrConfig}>
          <FeatureFlagProvider>
            <App />
          </FeatureFlagProvider>
        </SWRConfig>
      </ThemeProvider>
    </AuthenticationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
