import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Header } from '../header/Header';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { initAppInsights } from '../../services/useAppInsights';
import { AppRoutes } from './AppRoutes';
import { AppContextProvider } from './AppContext';

export const AppRouting = () => {
  initAppInsights();
  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
  return (
    <ErrorBoundary>
      <BrowserRouter basename={baseUrl}>
        <AppContextProvider>
          <Header />
          <AppRoutes />
        </AppContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
