import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate, formatDateOfBirth } from '../../utils/date';
import { Load, DownArrow, FloppyDisk } from '@instech/icons';
import { Label } from '../shared/formFields/Label';
import { InputField } from '../shared/formFields/InputField';
import { SelectField } from '../shared/formFields/SelectField';
import { DatePicker } from '../shared/datePicker/DatePicker';
import { useNationalities } from '../../services/useNationalities';
import { TriStateCheckbox } from '../shared/formFields/TriStateCheckbox';
import { Formik, Form } from 'formik';
import { BoxButton } from '@instech/components';
import { putPerson } from '../../services/postPutPersonData';
import { getPersonRolesById } from '../../services/usePersonRoles';
import { ErrorMessage } from 'components/shared/datePicker/Components';
import { ValidationSchemaEditPerson } from '../companyRoles/validationSchema';
import { ConfirmModalDialog } from '../shared/ConfirmModalDialog';
import { PersonRoleRow } from './PersonRoleRow';

const ArrowDown = styled(DownArrow)`
  justify-self: right;
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
`;
const ArrowUp = styled(ArrowDown)`
  transform: rotate(180deg);
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
  padding: 16px 32px;
`;
const StyledLoad = styled(Load)`
  height: 20px;
`;
const ButtonsWrapper = styled.div`
  margin: 0 32px 16px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;
const PersonRow = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.5fr 0.5fr;
  color: ${(props) => props.theme.marineBlue};
  padding: 16px 32px;
`;
const EditSectionHeader = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
`;
const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.5fr 0.5fr;
  align-items: start;
  grid-gap: 24px;
  margin: 24px;
`;
const PepCheckbox = styled.div`
  justify-self: center;
  margin-top: 5px;
`;
const SaveButtonWrapper = styled.div`
  margin: 32px 0 0 auto;
`;
const EditPersonSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  margin: 16px 0;
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
`;
const CloseButton = styled.div`
  text-align: right;
`;
const PersonsTable = styled.div`
  font-size: 14px;
  margin: 10px 0;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.marineBlue};
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.flatWhite};
  font-weight: bold;
`;
const OtherPersonRolesWrapper = styled.div`
  margin: 16px;
`;

const getPoliticallyExposedText = (isPoliticallyExposed, acceptedHighRisk) => {
  if (isPoliticallyExposed) {
    return acceptedHighRisk ? 'YES - Accepted HR' : 'YES'
  }
  return isPoliticallyExposed === null ? '-' : 'No';
};

const getNationalityValue = (nationalities, selected) => {
  const nationality = nationalities.find((n) => n.id === selected);
  if (!nationality) return null;
  return {
    id: nationality.id,
    displayName: nationality.name,
    partitionKey: nationality.twoLetterCountryCode,
  }
};

export const PersonItem = ({ item, mutate }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalDialogVisible, setIsModalDialogVisible] = useState(false);
  const nationalities = useNationalities();
  const [isEditPersonOpen, setIsEditPersonOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showConfirmRefresh, setShowConfirmRefresh] = useState(false);
  const [updatedBy, setUpdatedBy] = useState('');
  const [personRoles, setPersonRoles] = useState([]);

  useEffect(() => {
    const fetchPersonRoles = async () => {
      try {
        const personRolesData = await getPersonRolesById(item.id);
        const activeRoles = personRolesData.filter((item) => item.role.isActive === true);
        setPersonRoles(activeRoles);
      } catch (error) {
        console.log(error);
      }
    };
    if (isEditPersonOpen) {
      fetchPersonRoles();
    }
  }, [isEditPersonOpen, item.id]);

  const clickEditPersonHandler = async () => {
    setIsEditPersonOpen((isOpen) => !isOpen);
  };

  const showModal = () => {
    setIsModalDialogVisible(true);
    setTimeout(() => {
      setIsModalDialogVisible(false);
    }, 2000);
  };

  const handleEditPerson = (values) => {
    setIsSaving(true);
    setShowConfirmRefresh(false);
    const updatePerson = {
      data: {
        dateOfBirth: formatDateOfBirth(values.person.dateOfBirth),
        eTag: values.person._etag,
        firstName: values.person.firstName,
        lastName: values.person.lastName,
        isPoliticallyExposed: values.person.isPoliticallyExposed,
        nationalityId: values.person.nationality.id,
        personId: values.person.id,
      },
    };
    putPerson(values.person.id, updatePerson, values.person._etag)
      .then(() => {
        setIsSaving(false);
        setIsError(false);
        setIsDirty(false);
        setShowConfirmRefresh(false);
        mutate();
        showModal();
      })
      .catch((error) => {
        setIsSaving(false);
        setIsError(true);
        console.log(error);
      });
  };
  const keepChangesHandler = useCallback(() => {
    setShowConfirmRefresh(false);
    setUpdatedBy(null);
  }, [setShowConfirmRefresh]);

  const refreshHandler = useCallback(() => {
    setShowConfirmRefresh(false);
    setUpdatedBy(null);
    mutate();
  }, [mutate, setUpdatedBy, setShowConfirmRefresh]);

  return !isEditPersonOpen ? (
    <PersonRow data-test-id="edit-person" onClick={clickEditPersonHandler}>
      <Span>{item.nationality.displayName}</Span>
      <Span>{item.firstName}</Span>
      <Span>{item.lastName}</Span>
      <Span>{item.dateOfBirth ? getFriendlyDate(item.dateOfBirth) : '-'}</Span>
      <Span>{getPoliticallyExposedText(item.isPoliticallyExposed, item.acceptedHighRisk)}</Span>
      <ArrowDown />
    </PersonRow>
  ) : (
    <Formik enableReinitialize initialValues={{ person: item }} validationSchema={ValidationSchemaEditPerson}>
      {({ values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched, dirty, isValid }) => {
        useEffect(() => {
          setIsDirty(dirty);
        }, [dirty]);

        return (
          <Form>
            {dirty && showConfirmRefresh && (
              <ConfirmModalDialog
                cancelButtonText="Keep unsaved changes"
                confirmButtonText="Refresh"
                onCancel={keepChangesHandler}
                onConfirm={refreshHandler}
              >
                {updatedBy ? (
                  <p>The person was updated on the server by {updatedBy}.</p>
                ) : (
                  <p>The person was updated on the server.</p>
                )}

                <p>Do you want to refresh with the latest data from the server, or keep your unsaved changes?</p>
              </ConfirmModalDialog>
            )}
            <EditPersonSection>
              <EditSectionHeader onClick={clickEditPersonHandler}>
                <CloseButton>
                  <ArrowUp />
                </CloseButton>
              </EditSectionHeader>
              <Grid>
                <div>
                  <Label label="Nationality" />
                  <SelectField
                    name={`person.nationality`}
                    placeholder="Not selected"
                    options={nationalities}
                    value={values.person.nationality?.id || null}
                    displayNameSelector={(x) => `${x.name}  (${x.twoLetterCountryCode})`}
                    valueNameSelector={(x) => x.id}
                    handleChange={(name, value) => {
                      const newValue = getNationalityValue(nationalities, value);
                      setFieldValue(name, newValue);
                    }}
                    handleBlur={setFieldTouched}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div>
                  <Label label="First Name" />
                  <InputField
                    name={`person.firstName`}
                    value={values.person.firstName}
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div>
                  <Label label="Last Name" />
                  <InputField
                    name={`person.lastName`}
                    value={values.person.lastName}
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div>
                  <Label label="Date of Birth" />
                  <DatePicker
                    name={`person.dateOfBirth`}
                    placeholder="Select a date"
                    value={values.person.dateOfBirth}
                  />
                </div>
                <div>
                  <Label label="PEP" />
                  <PepCheckbox>
                    <TriStateCheckbox
                      name={`person.isPoliticallyExposed`}
                      value={values.person.isPoliticallyExposed}
                      onClick={(newValue) => setFieldValue(`person.isPoliticallyExposed`, newValue)}
                    />
                  </PepCheckbox>
                </div>
              </Grid>
              <ButtonsWrapper>
                {isSaving && !isError ? (
                  <StyledLoad />
                ) : (
                  <SaveButtonWrapper>
                    <BoxButton
                      data-test-id="save-changes"
                      aria-label="Save changes"
                      onClick={() => handleEditPerson(values)}
                      disabled={!dirty || !isValid}
                      padding="10px 20px"
                      startIcon={<FloppyDisk />}
                      type="button"
                    >
                      SAVE
                    </BoxButton>
                  </SaveButtonWrapper>
                )}
              </ButtonsWrapper>
              {isModalDialogVisible && (
                <ConfirmModalDialog
                  text="Your changes have been saved."
                  isModalDialogVisible={isModalDialogVisible}
                  onClick={() => setIsModalDialogVisible(false)}
                />
              )}
              {isError && <ErrorMessage>Failed to save person data!</ErrorMessage>}
              <OtherPersonRolesWrapper>
                {personRoles?.length > 0
                  ? 'This person has these roles: '
                  : "This person doesn't have any roles. "}
                {personRoles?.length > 0 && (
                  <PersonsTable>
                    <TableHeader>
                      <div>Company/Object</div>
                      <div>Role</div>
                      <div>In Role</div>
                    </TableHeader>
                    {personRoles.map((item, index) => {
                      return (
                        <PersonRoleRow
                          key={index}
                          item={item}
                          mutate={mutate}
                          isSaving={isSaving}
                          isEditPersonOpen={isEditPersonOpen}
                          isDirty={isDirty}
                          setShowConfirmRefresh={setShowConfirmRefresh}
                          setUpdatedBy={setUpdatedBy}
                        />
                      );
                    })}
                  </PersonsTable>
                )}
              </OtherPersonRolesWrapper>
            </EditPersonSection>
          </Form>
        );
      }}
    </Formik>
  );
};

PersonItem.propTypes = {
  item: PropTypes.object,
  mutate: PropTypes.func,
  persons: PropTypes.array,
};
