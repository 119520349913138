import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StatusIcon } from '../shared/kyc-icons/StatusIcon';
import { VesselOwners } from './VesselOwners';
import { HasFlag } from '../../utils/validations/functions';
import { MissingData } from '../../utils/validations/types';

const IconWrapper = styled.span`
  vertical-align: middle;
  margin-right: 5px;
  grid-column: 1;
`;
const Wrapper = styled.div``;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));
  align-content: center;
  align-items: center;
  grid-gap: 2rem;
  padding: 10px;
  position: relative;
  background-color: ${ (props) => (props.isEvenRow ? props.theme.white : props.theme.flatWhite) };
`;
const VesselNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
`;
const VesselName = styled.div`
  display: flex;
  align-items: center;
  grid-column: 2;
`;

const getCountry = (address) => address;
const getOwnersByRoleType = (owners, itemProperty, object, roleTypes) => {
  let result = {
    beneficialOwners: [],
    registeredOwners: [],
  };

  owners?.length > 0 &&
    owners.forEach((item) => {
      const organizationId = item.kycOrganization ? item.kycOrganization.id : item[itemProperty].id;
      const name = item[itemProperty].displayName;
      const country = getCountry(item[itemProperty].displayAddress);
      const activePeriod = item.period;
      const roleName = item.roleType.roleName;
      const similarKycOrganizations = item.similarKycOrganizations;
      const owner = {
        organizationId,
        name,
        objectId: object.id,
        activePeriod,
        roleType: {},
        isOwnerAssured: false,
        // To use in next step to display selected vessels
        objectName: object.name,
        validations: object.validations,
        country,
        similarKycOrganizations,
      };

      // Existing companies have no kycOrganization property, therefore this condition
      if (item.kycOrganization || item.kycOrganization === null) owner.kycOrganization = item.kycOrganization;

      if (roleName === 'Beneficial Owner') {
        owner.roleType = roleTypes.beneficial;
        result.beneficialOwners.push(owner);
      } else if (roleName === 'Registered Owner') {
        owner.roleType = roleTypes.registered;
        result.registeredOwners.push(owner);
      }
    });
  return result;
};

const getFilteredOwners = (current, existing) => {
  return (
    current &&
    current.filter((c) => {
      const filteredItem = existing && existing.find((e) => e.organizationId === c.organizationId);
      return filteredItem ? c.organizationId !== filteredItem.organizationId : c;
    })
  );
};

export const VesselOwnersRow = ({
  insuredObjects,
  existingOwners,
  ownersFromLLI,
  checkedCompanies,
  setCheckedCompanies,
  setSelectedCompanies,
  roleTypes,
}) => {
  const handleCheck = (target, item, owners, separatedExistingOwners) => {
    if (target.checked) {
      const filteredOwners = {
        beneficialOwners: getFilteredOwners(owners?.beneficialOwners, separatedExistingOwners?.beneficialOwners),
        registeredOwners: getFilteredOwners(owners?.registeredOwners, separatedExistingOwners?.registeredOwners),
      };
      const allOwners = filteredOwners?.beneficialOwners.concat(filteredOwners?.registeredOwners);
      setSelectedCompanies((prev) => [
        ...allOwners.filter((a) => !a.similarKycOrganizations || a.similarKycOrganizations.length === 0),
        ...prev.filter((p) => p.objectId !== item.objectId),
      ]);
      setCheckedCompanies((prev) => [...allOwners, ...prev.filter((p) => p.objectId !== item.objectId)]);
    } else {
      setSelectedCompanies((prev) =>
        prev.filter(
          (p) =>
            p.organizationId !== item.organizationId ||
            p.objectId !== item.objectId ||
            p.roleType.id !== item.roleType.id
        )
      );
      setCheckedCompanies((prev) =>
        prev.filter(
          (p) =>
            p.organizationId !== item.organizationId ||
            p.objectId !== item.objectId ||
            p.roleType.roleName !== item.roleType.roleName
        )
      );
    }
  };
  return (
    <Wrapper>
      {insuredObjects &&
        insuredObjects.map((item, index) => {
          const separatedExistingOwners = getOwnersByRoleType(
            existingOwners[item.id]?.filter((x) => x.imo == item.imo),
            'organization',
            item,
            roleTypes
          );
          const separatedFromLLIOwners = getOwnersByRoleType(
            ownersFromLLI.filter((x) => x.imo == item.imo),
            'company',
            item,
            roleTypes
          );
          return (
            <Row key={index} isEvenRow={index % 2 === 0}>
              <VesselNameWrapper>
                {HasFlag(item?.validations?.missingData, MissingData.BeneficialOwner) && (
                  <IconWrapper>
                    <StatusIcon missingBOIcon text='Missing Beneficial Owner' />
                  </IconWrapper>
                )}
                <VesselName>{item.name}</VesselName>
              </VesselNameWrapper>
              <VesselOwners owners={separatedExistingOwners} roleType='beneficial' />
              <VesselOwners owners={separatedExistingOwners} roleType='registered' />{' '}
              <VesselOwners
                owners={separatedFromLLIOwners}
                roleType='beneficial'
                existingOwners={separatedExistingOwners}
                hasCheckBox={true}
                handleCheck={handleCheck}
                checkedCompanies={checkedCompanies}
              />
              <VesselOwners
                owners={separatedFromLLIOwners}
                roleType='registered'
                existingOwners={separatedExistingOwners}
                hasCheckBox={true}
                handleCheck={handleCheck}
                checkedCompanies={checkedCompanies}
              />
            </Row>
          );
        })}
    </Wrapper>
  );
};

VesselOwnersRow.propTypes = {
  insuredObjects: PropTypes.array,
  existingOwners: PropTypes.object,
  ownersFromLLI: PropTypes.array,
  setSelectedCompanies: PropTypes.func,
  checkedCompanies: PropTypes.array,
  setCheckedCompanies: PropTypes.func,
  roleTypes: PropTypes.object,
};
