import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LinkForm } from './LinkForm';
import { Trash, Pen, Close, Load } from '@instech/icons';
import { deleteLink } from '../../services/useOrganizationLinks';
import { useEventStream } from '../../services/useEventStream';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';
import { useCompanyContext } from '../company/CompanyContext';
import { ContentBlock } from '../shared/ContentBlock';

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
`;

const StyledTrash = styled(Trash)`
  color: ${(props) => props.theme.lightRed};
  transition: color 1s ease-out;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.red};
  }
`;
const StyledEdit = styled(Pen)`
  color: ${(props) => props.theme.marineBlue50};
  transition: color 1s ease-out;
  margin-right: 8px;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.marineBlue};
  }
`;
const StyledClose = styled(Close)`
  cursor: pointer;
  align-self: start;
  :hover {
    transform: scale(1.3);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.isEvenRow ? props.theme.white : props.theme.flatWhite)};
`;
const Table = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
const Title = styled.div`
  font-weight: bold;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: ${(props) => (props.isEvenRow ? props.theme.white : props.theme.flatWhite)};
`;
const ErrorMessage = styled.div`
  color: red;
  text-align: right;
`;

const LinkPath = styled.div`
  color: ${(props) => props.theme.marineBlue50};
`;
const LinkName = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.marineBlue};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CompanyLinks = ({ organizationId, etag, externalLinks }) => {
  const [errorId, setErrorId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editLinkId, setEditLinkId] = useState(null);
  const [removeLinkId, setRemoveLinkId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useCompanyContext();

  const handleRemoveLink = (linkId) => {
    setRemoveLinkId(linkId);
    setIsLoading(true);
    setErrorId(null);
    setErrorMessage(null);
    deleteLink(organizationId, linkId, etag).then(
      () => {
        setIsLoading(false);
        mutate();
      },
      (error) => {
        setIsLoading(false);
        setErrorId(linkId);
        setErrorMessage(formatErrorMessage(error));
        console.log('put error', error);
      }
    );
  };

  const eventStreamTopic = '/Organization/' + organizationId + '/' + organizationId + '/*';

  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamTopic);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  useEffect(() => {
    onEvent((event) => {
      console.info('Received event on topic', eventStreamTopic, event);

      // Tell SWR to reload data from server
      mutate();
    });

    return eventHandlerCleanup;
  }, [mutate, eventStreamTopic]);

  return (
    <ContentBlock title="Create New Link">
      <LinkForm organizationId={organizationId} etag={etag} mutate={mutate} isEmptyForm={true} />
      {externalLinks.length > 0 && <Title>External Company Links</Title>}
      <Table>
        {externalLinks.map((item, index) => {
          const href =
            item.url.indexOf('http://') !== -1 || item.url.indexOf('https://') !== -1
              ? item.url
              : 'https://' + item.url;
          return (
            <RowWrapper key={index} isEvenRow={index % 2 === 0}>
              <Row isEvenRow={index % 2 === 0}>
                {editLinkId === item.id ? (
                  <LinkForm
                    organizationId={organizationId}
                    etag={etag}
                    mutate={mutate}
                    item={item}
                    setEditLinkId={setEditLinkId}
                    isEmptyForm={false}
                  />
                ) : (
                  <LinkName href={href} target="_blank">
                    {item.description}
                    <LinkPath>{item.url}</LinkPath>
                  </LinkName>
                )}
                {editLinkId === item.id ? (
                  <StyledClose data-test-id="close-edit" onClick={() => setEditLinkId(null)} />
                ) : (
                  <div>
                    {isLoading && removeLinkId === item.id ? (
                      <StyledLoad />
                    ) : (
                      <div>
                        <StyledEdit data-test-id="edit-link" onClick={() => setEditLinkId(item.id)} />
                        <StyledTrash data-test-id="remove-link" onClick={() => handleRemoveLink(item.id)} />
                      </div>
                    )}
                  </div>
                )}
              </Row>
              <ErrorMessage>{errorId && errorId === item.id && errorMessage}</ErrorMessage>
            </RowWrapper>
          );
        })}
      </Table>
    </ContentBlock>
  );
};

CompanyLinks.propTypes = {
  organizationId: PropTypes.string,
  etag: PropTypes.string,
  mutate: PropTypes.func,
  externalLinks: PropTypes.array,
};
