import React from 'react';
import styled from 'styled-components';
import { CountryMenu } from './CountryMenu';
import { MenuDots, HighRisk } from '@instech/icons';
import PropTypes from 'prop-types';

const StyledMenuDots = styled(MenuDots)`
  transform: rotate(90deg);
  height: 16px;
  border-bottom: 1px solid ${(props) => props.theme.whiteBlue};
  margin-left: 8px;
  :hover {
    cursor: pointer;
  }
`;
const StyledHighRisk = styled(HighRisk)`
  margin-right: 8px;
  color: ${(props) => props.theme.status.red};
`;

const Item = styled.div`
  background-color: ${(props) => (props.redBackground ? props.theme.background.red : props.theme.white)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isUserRoleAllowed ? '8px 0 8px 8px' : '8px')};
  margin: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  position: relative;
`;

const ItemWrapper = styled.div`
  position: relative;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

export const CountryItem = ({
  country,
  clickOpenCountryMenu,
  openCountryMenuId,
  handleChange,
  isHighRiskCountry = false,
  node,
  redBackground,
  isStatusChanged,
  disabled,
  isUserRoleAllowed,
}) => {
  return (
    <ItemWrapper disabled={disabled}>
      <Item redBackground={redBackground && isStatusChanged} isUserRoleAllowed={isUserRoleAllowed}>
        {isHighRiskCountry && <StyledHighRisk />}
        <span>{country.name || country.value}</span>
        {isUserRoleAllowed && (
          <StyledMenuDots data-test-id="country-menu" onClick={() => clickOpenCountryMenu(country.id)} />
        )}
      </Item>
      {openCountryMenuId === country.id && (
        <CountryMenu isHighRiskCountry={isHighRiskCountry} handleChange={handleChange} node={node} />
      )}
    </ItemWrapper>
  );
};

CountryItem.propTypes = {
  country: PropTypes.object,
  clickOpenCountryMenu: PropTypes.func,
  openCountryMenuId: PropTypes.string,
  handleChange: PropTypes.func,
  isHighRiskCountry: PropTypes.bool,
  node: PropTypes.object,
  redBackground: PropTypes.bool,
  isStatusChanged: PropTypes.bool,
  disabled: PropTypes.bool,
  isUserRoleAllowed: PropTypes.bool,
};
