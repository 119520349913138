import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate } from '../../utils/date';
import { Load } from '@instech/icons';
import { Checkbox } from '../shared/formFields/CheckboxForGroup';

const StyledLoad = styled(Load)`
  height: 20px;
  margin: 32px auto;
`;
const ErrorMessage = styled.div`
  color: red;
  padding: 8px;
  margin: 16px 0;
  background: rgba(255, 0, 0, 0.1);
`;
const NoDataMessage = styled.div`
  padding: 8px;
  margin: 16px 0;
`;
const Wrapper = styled.div``;
const OwnersListWrapper = styled.div`
  margin: 32px 32px 64px 32px;
`;
const Content = styled.div`
  margin: 32px 0;
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.flatWhite};
  font-weight: bold;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  :nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

export const OwnersList = ({ checkedOwners, handleCheckOwner, ownersList, error }) => {
  return (
    <Wrapper>
      <OwnersListWrapper>
        <Content>
          <div>
            <TableHeader>
              <div>Role</div>
              <div>Role Period</div>
              <div>Company Name</div>
              <div>Address</div>
              <div>LEI</div>
              <div>Select Role</div>
            </TableHeader>
            {ownersList
              ? ownersList.map((item) => (
                  <TableRow key={item.id}>
                    <div>{item.roleType.roleName}</div>
                    <div>
                      {item.period.from && getFriendlyDate(item.period.from)}
                      &nbsp; - &nbsp;
                      {item.period.to && getFriendlyDate(item.period.to)}
                    </div>
                    <div>
                      {item.kycOrganization != null ? item.kycOrganization.displayName : item.company.displayName}
                    </div>
                    <div>
                      {item.kycOrganization != null ? item.kycOrganization.displayAddress : item.company.displayAddress}
                    </div>
                    <div>{item.company.lei}</div>
                    {item.roleExistsInKyc ? (
                      'Part of the KYC-portal'
                    ) : (
                      <Checkbox
                        name='chosenOwnersIds'
                        checked={checkedOwners.some(
                          (c) => c.company.id === item.company.id && c.roleType.roleName === item.roleType.roleName
                        )}
                        onChange={(e) => handleCheckOwner(e.target, item)}
                        value={item.organizationId}
                      />
                    )}
                  </TableRow>
                ))
              : !ownersList && !error && <StyledLoad />}
            {!ownersList && error && <ErrorMessage>Failed to load data!</ErrorMessage>}
            {ownersList && ownersList.length === 0 && <NoDataMessage>No data available </NoDataMessage>}
          </div>
        </Content>
      </OwnersListWrapper>
    </Wrapper>
  );
};

OwnersList.propTypes = {
  checkedOwners: PropTypes.array,
  handleCheckOwner: PropTypes.func,
  ownersList: PropTypes.array,
  error: PropTypes.object,
};
