import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Approved, Trash } from '@instech/icons';
import { QuotedFleetModalDialog } from './QuotedFleetModalDialog';
import { useActionsForQuotedFleetsContext } from './ActionsForQuotedFleetsContext';

const StyledApproved = styled(Approved)`
  color: ${(props) => props.theme.green75};
  transition: color 1s ease-out;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.darkGreen};
  }
`;
const StyledTrash = styled(Trash)`
  color: ${(props) => props.theme.lightRed};
  align-self: center;
  margin-right: 8px;
  transition: color 1s ease-out;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.red};
  }
`;
const Container = styled.div`
  margin-right: 16px;
  background: ${(props) => props.theme.flatWhite};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  position: absolute;
  z-index: 99;
  bottom: 32px;
  left: -12px;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.lightGray};
  padding: 8px 16px;
  :hover {
    background: ${(props) => props.theme.border.blueGray};
  }
`;

export const QuotedFleetMenu = ({ node }) => {
  const {
    isRemoveDialogVisible,
    setIsRemoveDialogVisible,
    isAcceptDialogVisible,
    setIsAcceptDialogVisible,
    isSubmitted,
  } = useActionsForQuotedFleetsContext();

  return (
    <Container ref={node}>
      <Item data-test-id="remove-fleet" onClick={() => setIsRemoveDialogVisible(true)}>
        <StyledTrash />
        <span>Remove</span>
      </Item>
      <Item data-test-id="accept-fleet" onClick={() => setIsAcceptDialogVisible(true)}>
        <StyledApproved />
        <span>Accept</span>
      </Item>
      {(isRemoveDialogVisible || isAcceptDialogVisible) && !isSubmitted && <QuotedFleetModalDialog />}
    </Container>
  );
};

QuotedFleetMenu.propTypes = {
  node: PropTypes.object,
};
