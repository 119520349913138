import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Vessel } from '../vesselsList/Vessel';
import { VesselsListHeader } from '../vesselsList/VesselsListHeader';

const VesselsTable = styled.div`
  border: 1px solid ${(props) => props.theme.marineBlue50};
`;
const Wrapper = styled.div`
  background-color: ${(props) => (props.isEvenRow ? props.theme.white : props.theme.flatWhite)};
`;

export const VesselsTableForMultipleChoice = ({
  vesselsList,
  handleCheckVessel,
  hasCheckBox,
  handleSort,
  sortOrder,
  claims,
  objectIds,
  setObjectIds,
  interestCodesByObjectId,
  setInterestCodesByObjectId,
}) => {
  return (
    <VesselsTable>
      <VesselsListHeader
        handleSort={handleSort}
        setObjectIds={setObjectIds}
        objectIds={objectIds}
        setInterestCodesByObjectId={setInterestCodesByObjectId}
        interestCodesByObjectId={interestCodesByObjectId}
        sortOrder={sortOrder}
      />
      {vesselsList &&
        vesselsList.length > 0 &&
        vesselsList.map((item, index) => {
          return (
            <Wrapper key={item.id} isEvenRow={index % 2 === 0}>
              <Vessel
                item={item}
                claims={claims}
                hasCheckBox={hasCheckBox}
                handleCheckVessel={(e) => handleCheckVessel && handleCheckVessel(e)}
              />
            </Wrapper>
          );
        })}
    </VesselsTable>
  );
};

VesselsTableForMultipleChoice.propTypes = {
  vesselsList: PropTypes.array,
  handleCheckVessel: PropTypes.func,
  hasCheckBox: PropTypes.bool,
  handleSort: PropTypes.func,
  sortOrder: PropTypes.string,
  claims: PropTypes.array,
  objectIds: PropTypes.array,
  setObjectIds: PropTypes.func,
  interestCodesByObjectId: PropTypes.array,
  setInterestCodesByObjectId: PropTypes.func,
};
