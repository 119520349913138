import React from 'react';
import styled from 'styled-components';
import { CompanyHeader } from '../company/CompanyHeader';
import { CompanyData } from '../company/CompanyData';
import { useCompanyContext } from './CompanyContext';

const Wrapper = styled.div`
  width: 100%;
`;
export const Company = () => {
  const { isClientOpen } = useCompanyContext();
  return (
    <Wrapper>
      <CompanyHeader />
      {isClientOpen && <CompanyData />}
    </Wrapper>
  );
};
