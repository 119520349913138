import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  useRiskLevelAcceptanceLogForFleet,
  useMissingDataAcceptanceLogForFleet,
} from '../../services/useAcceptanceLogs';
import { LogDataCover } from './LogDataCover';
import { LogDataOrganization } from './LogDataOrganization';
import { LogDataInsuredObject } from './LogDataInsuredObject';
import { MissingInfo, HighRisk, MissingRole, LeftArrow, RightArrow, MissingUbo } from '@instech/icons';
import { AcceptanceType } from "../../utils/AcceptanceType";
import { LogDataPerson } from "./LogDataPerson";
import { LogDataManualHighRisk } from "./LogDataManualHighRisk";

const StyledMissingInfo = styled(MissingInfo)`
  color: ${(props) => props.theme.yellow};
`;
const StyledHighRisk = styled(HighRisk)`
  color: ${(props) => props.theme.status.red};
`;
const StyledMissingPersonRoles = styled(MissingRole)`
  color: ${(props) => props.theme.status.yellow};
`;
const StyledMissingUbo = styled(MissingUbo)`
  color: ${(props) => props.theme.status.yellow};
`;
const StyledLeftArrow = styled(LeftArrow)`
  cursor: pointer;
  :hover {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const StyledRightArrow = styled(RightArrow)`
  cursor: pointer;
  :hover {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const Wrapper = styled.div`
  margin: 32px 0;
  padding: 16px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
`;
const Table = styled.div`
  background: ${(props) => props.theme.white};
  border: 2px solid ${(props) => props.theme.marineBlue50};
`;
const Title = styled.h3`
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
  span {
    margin-left: 4px;
  }
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 2fr) repeat(7, minmax(80px, 1fr)) minmax(150px, 2fr);
  grid-gap: 8px;
  border-bottom: 2px solid ${(props) => props.theme.marineBlue75};
  font-weight: bold;
  padding: 16px 8px;
  align-items: center;
`;
const TableRows = styled.div`
  :nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const PageInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.marineBlue};
  margin-top: 10px;
  padding-bottom: 10px;
  line-height: 24px;
`;

const CurrentPageInfo = styled.span`
  font-size: 12px;
`;
const Notification = styled.div`
  padding: 32px;
`;

const SetPage = (currentPage, items, itemsPerPage) => {
  const indexOfFirst = (currentPage - 1) * itemsPerPage;
  const indexOfLast = Math.min(indexOfFirst + itemsPerPage, items && items.length ? items.length : 0);
  return items && items.length ? items.slice(indexOfFirst, indexOfLast) : [];
};

export const AcceptanceLogForFleet = ({ fleetId, acceptanceType, covers }) => {
  const itemsPerPage = 5;

  const { data: riskLevelAcceptanceLog } = useRiskLevelAcceptanceLogForFleet(fleetId);
  const { data: missingDataAcceptanceLog } = useMissingDataAcceptanceLogForFleet(fleetId);

  const acceptanceLog = useMemo(() =>
    acceptanceType === AcceptanceType.RiskLevel
      ? riskLevelAcceptanceLog
      : missingDataAcceptanceLog?.filter((item) => item.acceptanceType === acceptanceType)
  , [riskLevelAcceptanceLog, missingDataAcceptanceLog]);

  const [currentPage, setCurrentPage] = useState(1);
  const currentIndexCount = acceptanceLog && acceptanceLog.length ? acceptanceLog.length : 0;
  const currentIndexFirst = (currentPage - 1) * itemsPerPage + 1;
  const currentIndexLast = Math.min(currentPage * itemsPerPage, currentIndexCount);

  const page = SetPage(currentPage, acceptanceLog, itemsPerPage);

  const handlePages = (pageChange) => {
    const maxPages = Math.ceil(currentIndexCount / itemsPerPage);
    var newCurrentPage = currentPage + pageChange;

    if (newCurrentPage < 1) {
      newCurrentPage = 1;
    }

    if (newCurrentPage > maxPages) {
      newCurrentPage = maxPages;
    }

    setCurrentPage(newCurrentPage);
  };

  return (
    <Wrapper>
      <Title>
        {acceptanceType===AcceptanceType.RiskLevel && <StyledHighRisk />}
        {acceptanceType===AcceptanceType.RiskLevel && <span>High risk Acceptance Log</span>}
        {acceptanceType===AcceptanceType.MissingData && <StyledMissingInfo />}
        {acceptanceType===AcceptanceType.MissingData && <span>Missing info Acceptance Log</span>}
        {acceptanceType===AcceptanceType.MissingPersonRoles && <StyledMissingPersonRoles />}
        {acceptanceType===AcceptanceType.MissingPersonRoles && <span>Missing roles Acceptance Log</span>}
        {acceptanceType===AcceptanceType.MissingUbo && <StyledMissingUbo />}
        {acceptanceType===AcceptanceType.MissingUbo && <span>Missing UBO Acceptance Log</span>}
      </Title>
      <Table>
        <TableHeader>
          <span>Name</span>
          <span>External ID</span>
          <span>Risk Number</span>
          <span>Cover Note</span>
          <span>Accepted from</span>
          <span>Accepted to</span>
          <span>Accepted by</span>
          <span>Accepted Date/Time</span>
          <span>Comments</span>
        </TableHeader>
        <TableRows>
          {page.map((item) => {
            if (item.entityType === 'ManualHighRiskForFleet') return <LogDataManualHighRisk key={item.id} item={item} />;
            if (item.entityType === 'Cover') return <LogDataCover key={item.id} item={item} covers={covers} />;
            if (item.entityType === 'Organization') return <LogDataOrganization key={item.id} item={item} />;
            if (item.entityType === 'InsuredObject') return <LogDataInsuredObject key={item.id} item={item} />;
            if (item.entityType === 'Person') return <LogDataPerson key={item.id} item={item} />;
          })}
        </TableRows>
        {page && page.length === 0 && <Notification>There is no data available </Notification>}
        <PageInfoContainer>
          <StyledLeftArrow data-test-id="navigate-left" onClick={() => handlePages(-1)} />{' '}
          <CurrentPageInfo>
            {currentIndexFirst} - {currentIndexLast} of {currentIndexCount}
          </CurrentPageInfo>
          <StyledRightArrow data-test-id="navigate-right" onClick={() => handlePages(+1)} />
        </PageInfoContainer>
      </Table>
    </Wrapper>
  );
};

AcceptanceLogForFleet.propTypes = {
  fleetId: PropTypes.string,
  covers: PropTypes.array,
  acceptanceType: PropTypes.oneOf([AcceptanceType.RiskLevel, AcceptanceType.MissingData, AcceptanceType.MissingPersonRoles, AcceptanceType.MissingUbo ])
};
