import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes, { object } from 'prop-types';
import { CompanyItem } from './CompanyItem';
import { Load } from '@instech/icons';
import { constants } from '../../utils/constants';

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
  margin: 32px;
`;
const Wrapper = styled.div`
  color: ${ (props) => props.theme.marineBlue };
  margin-top: 32px;
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  transition: 0.3s;

  :nth-child(even) {
    background: ${ (props) => props.theme.flatWhite };
  }
  :nth-child(odd) {
    background: ${ (props) => props.theme.white };
  }
`;
const NoResultMessage = styled.div`
  font-size: 36px;
  margin: 62px;
  text-align: center;
  color: ${ (props) => props.theme.marineBlue };
`;

export const CompanyItems = ({ companies, mutate, hasMore, loading, limit, shouldFetch, dispatch, offset }) => {
  const observer = useRef();
  const lastCompanyElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch({ type: constants.SET_OFFSET, value: offset + limit });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, offset, limit, dispatch]
  );
  return companies && companies.length > 0 ? (
    <Wrapper>
      <Ul>
        {companies.map((company, index) => {
          return (
            <Li key={index} {...(companies.length === index + 1 ? { ref: lastCompanyElementRef } : {})}>
              <CompanyItem item={company} mutate={mutate} />
            </Li>
          );
        })}
      </Ul>
      {loading && <StyledLoad />}
    </Wrapper>
  ) : companies && companies.length === 0 && shouldFetch && !loading ? (
    <NoResultMessage>no results found</NoResultMessage>
  ) : null;
};

CompanyItems.propTypes = {
  companies: PropTypes.arrayOf(object),
  mutate: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  limit: PropTypes.number,
  shouldFetch: PropTypes.bool,
};
