import React from 'react';
import styled from 'styled-components';
import { Approved } from '@instech/icons';
import { usePagination, DOTS } from 'utils/usePagination';

const StyledCheck = styled(Approved)`
  color: ${(props) => props.theme.green};
  width: 16px;
  height: auto;
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageNumber = styled.button<{ selected?: boolean; isPageChecked?: boolean }>`
  background: ${(props) =>
    props.selected
      ? props.theme.border.blueGray
      : props.isPageChecked
      ? props.theme.lightGreen
      : props.theme.white};
  color: ${(props) => props.theme.marineBlue};
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: 1px solid ${(props) => props.theme.lightBlue};
  width: 48px;
  height: 48px;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.lightBlue};
    border: 1px solid transparent;
  }
  :focus,
  :active {
    border: 1px solid transparent;
    outline: none;
  }

  :disabled {
    opacity: 0.5;
  }
`;

const Div = styled.div`
  display: flex;
  align-self: baseline;
  justify-content: center;
`;
interface PaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  checkedPages?: number[];
  numberOfPages: number;
}
export const Pagination = ({
  totalCount,
  pageSize,
  currentPage,
  setCurrentPage,
  checkedPages,
  numberOfPages,
}: PaginationProps) => {
  const numberOfPagesArray = usePagination({ currentPage, totalCount, pageSize, numberOfPages });
  const lastPage = numberOfPagesArray && numberOfPagesArray[numberOfPagesArray.length - 1];
  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };
  return (
    <Wrapper>
      <PageNumber onClick={onPrevious} disabled={currentPage === 1}>
        &#60;
      </PageNumber>
      {numberOfPagesArray?.map((n, i) => {
        return (
          <div key={i}>
            {n === DOTS ? (
              DOTS
            ) : (
              <PageNumber
                onClick={() => setCurrentPage(n)}
                selected={n === currentPage}
                isPageChecked={checkedPages?.includes(n)}
              >
                <Div>{checkedPages?.includes(n) ? <StyledCheck /> : n}</Div>
              </PageNumber>
            )}
          </div>
        );
      })}
      <PageNumber onClick={onNext} disabled={currentPage === lastPage}>
        &#62;
      </PageNumber>
    </Wrapper>
  );
};
