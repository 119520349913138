import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BoxButton, ButtonGroup } from '@instech/components';
import { constants } from '../../utils/constants';
import { useAppContext } from '../appRouting/AppContext';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: scroll;
`;
const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 25%;
  right: 25%;
  top: 15%;
  bottom: 35%;
  height: fit-content;
  margin: auto;
  background: white;
  padding: 0;
`;
const FormWrapper = styled.div`
  padding: 0 16px 16px 16px;
  color: ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.white};
`;
const TextWrapper = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  padding: 20px;
`;
const Button = styled.button`
  align-self: flex-end;
  margin: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
  color: #de350b;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  transition: all 0.2s ease;
  :hover {
    background-color: #ffbdad;
    transform: scale(1.3);
    cursor: pointer;
  }
`;
const ButtonsWrapper = styled.div`
  grid-column: 3;
  @media (max-width: 960px) {
    margin-top: 16px;
  }
`;

export const ConfirmModalDialog = ({ text, additionalText, cancelButtonText, confirmButtonText, onCancel, onConfirm }) => {
  const node = useRef();
  const { dispatch } = useAppContext();
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    dispatch({ type: constants.SET_IS_CONFIRM_DIALOG_VISIBLE, value: false });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  return (
    <Wrapper >
      <Dialog ref={node}>
        <Button onClick={onCancel}>x</Button>
        <FormWrapper>
          <TextWrapper>
            <div>{text}</div>
            <div>{additionalText}</div>
          </TextWrapper>
          <ButtonsWrapper>
            <ButtonGroup buttonGap='16px' alignRight>
              {confirmButtonText && (
                <BoxButton aria-label='Accept' onClick={onConfirm} type='button'>
                  {confirmButtonText.toUpperCase()}
                </BoxButton>
              )}
              {onCancel && cancelButtonText && (
                <BoxButton aria-label='Cancel' onClick={onCancel} padding='10px 20px' title='' type='button' inverted>
                  {cancelButtonText.toUpperCase()}
                </BoxButton>
              )}
            </ButtonGroup>
          </ButtonsWrapper>
        </FormWrapper>
      </Dialog>
    </Wrapper>
  );
};

ConfirmModalDialog.propTypes = {
  text: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
