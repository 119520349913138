import React, { useState, useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, useLocation } from 'react-router-dom';
import { ConfirmModalDialog } from './ConfirmModalDialog';

export function useBlocker(blocker, when) {
  const { navigator } = useContext(NavigationContext);
  const location = useLocation();

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      //if future path differs from current && company unsaved changes, block navigation
      tx.location.pathname !== location.pathname && blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when, location.pathname]);
}

export const NavBlocker = ({ when }) => {
  const [block, setBlock] = useState(when);
  const [visible, setVisible] = useState(false);
  const [tx, setTx] = useState();
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const skip = () => {
    setBlock(false);
    hide();
  };

  useEffect(() => {
    setBlock(when);
  }, [when]);

  useEffect(() => {
    !block && tx && tx.retry();
  }, [block, tx]);

  useBlocker((tx) => {
    setTx(tx);
    show();
  }, block);

  if (!visible) return '';
  return (
    <ConfirmModalDialog
      text='Are you sure you want to leave? You have unsaved changes.'
      cancelButtonText='Cancel'
      confirmButtonText='Leave'
      onCancel={hide}
      onConfirm={skip}
    />
  );
};
