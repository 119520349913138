import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BoxButton, IconButton } from '@instech/components';
import { SelectField } from '../shared/formFields/SelectField';
import { InputField } from '../shared/formFields/InputField';
import { Label } from '../shared/formFields/Label';
import { useNationalities } from '../../services/useNationalities';
import { usePersonRoleTypes } from '../../services/usePersonRoleTypes';
import { Formik } from 'formik';
import { TriStateCheckbox } from '../shared/formFields/TriStateCheckbox';
import { DatePicker } from '../shared/datePicker/DatePicker';
import { Close, Load, Search } from '@instech/icons';
import { ExistingPersonsNotification } from './ExistingPersonsNotification';
import { NIL as uuidNil } from 'uuid';
import { postNewPerson, postNewRole, getPersonRoles } from '../../services/postPutPersonData';
import { ValidationSchemaPerson } from './validationSchema';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';
import { CreatePerson } from './CreatePerson';
import { ApprovedNotification } from '../shared/ApprovedNotification';
import { WarningNotification } from '@instech/components';
import { formatDateOfBirth } from '../../utils/date';

const StyledLoad = styled(Load)`
  height: 20px;
  margin: 10px;
`;
const StyledClose = styled(Close)`
  color: ${(props) => props.theme.marineBlue};
  margin: 14px 0;
`;
const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.marineBlue15};
  margin: 16px 0;
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
`;
const ContentWrapper = styled.div`
  margin: 0 20px;
`;
const Title = styled.div`
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue15};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 16px;
  padding: 32px 0;
`;
const Pep = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
`;
const PepWrapper = styled.div`
  justify-self: center;
`;
const SearchButtonWrapper = styled.div`
  margin-top: 18px;
`;
const NotificationsWrapper = styled.div`
  padding-bottom: 32px;
`;
const getUniquePersons = (persons) => {
  let unique = persons.reduce((unique, o) => {
    if (!unique.some((obj) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, []);
  return unique;
};

export const NewPersonForm = ({ onSubmit, clientId, etag, isUbo, fleetId, vesselId, openNewPersonForm }) => {
  const [existingPersons, setExistingPersons] = useState(null);
  const [nationalityId, setNationalityId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [isPersonsTableOpen, setIsPersonsTableOpen] = useState(false);
  const [isRoleOpen, setIsRoleOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setIsRoleOpen(false);
    setIsPersonsTableOpen(false);
    setIsError(false);
    setErrorMessage(null);
  }, [nationalityId, firstName, lastName, dateOfBirth]);

  const handleNationalityChange = (value) => {
    setNationalityId(value);
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handlePersonSearch = (setFieldValue) => {
    setIsLoading(true);
    setIsSubmitted(false);
    setFieldValue(`role.type`, '');
    setFieldValue(`role.activePeriod.from`, null);
    setFieldValue(`role.activePeriod.to`, null);
    setDateOfBirth(null);
    const personParams = {
      firstName: firstName,
      nationalityId: nationalityId,
      lastName: lastName,
      dateOfBirth: formatDateOfBirth(dateOfBirth),
    };
    getPersonRoles(personParams).then((response) => {
      response.length > 0 ? setIsPersonsTableOpen(true) : setIsRoleOpen(true);
      setExistingPersons(response);
      setIsLoading(false);
    });
  };

  const nationalities = useNationalities();
  const roleTypes = usePersonRoleTypes();

  const initialValues = {
    organizationId: clientId,
    id: uuidNil,
    firstName: '',
    lastName: '',
    nationality: '',
    dateOfBirth: null,
    isPoliticallyExposed: null,
  };

  const onSubmitSuccess = (resetForm) => {
    onSubmit();
    setIsRoleOpen(false);
    setIsPersonsTableOpen(false);
    setIsSubmitted(true);
    resetForm(initialValues);
    setIsSaving(null);
  };

  const onSubmitError = (error, id) => {
    const errorText = formatErrorMessage(error);
    setIsError(true);
    setIsSaving(null);
    setErrorMessage({ id, text: errorText });
    console.log('post error', error);
  };

  const handleSubmit = async (values, resetForm, isUbo) => {
    setIsSaving({ id: values.isPersonNew ? 'new' : values.id });
    setIsError(false);
    setErrorMessage(null);
    setIsSubmitted(false);

    const newRoleParams = {
      isUbo,
      organizationId: isUbo ? null : clientId,
      fleetId,
      objectId: vesselId,
      personId: values.id,
      roleType: values.role.type,
      activePeriod: values.role.activePeriod,
      etag,
    };
    if (values.isPersonNew) {
      const person = {
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: formatDateOfBirth(values.dateOfBirth),
          nationalityId: values.nationality.id,
          isPoliticallyExposed: values.isPoliticallyExposed,
        },
      };

      postNewPerson(person).then(
        (response) => {
          newRoleParams.personId = response.id;
          postNewRole(newRoleParams, etag).then(
            () => {
              onSubmitSuccess(resetForm);
            },
            (error) => {
              onSubmitError(error, values.id);
            }
          );
        },
        (error) => {
          onSubmitError(error, values.id);
        }
      );
    } else {
      postNewRole(newRoleParams, etag).then(
        () => {
          onSubmitSuccess(resetForm);
        },
        (error) => {
          onSubmitError(error, values.id);
        }
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialTouched={{
        nationality: true,
        firstName: true,
        lastName: true,
        dateOfBirth: true,
      }}
      validationSchema={ValidationSchemaPerson}
    >
      {({ values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched, dirty, resetForm }) => {
        const uniquePersons = existingPersons && existingPersons.length > 0 ? getUniquePersons(existingPersons) : null;

        const shouldSearch = !!values.nationality && !!values.firstName && !!values.lastName;

        const createPersonRole = (values) => {
          handleSubmit(
            {
              isPersonNew: true,
              ...values,
            },
            resetForm,
            isUbo
          );
        };

        const addExistingPersonRole = (values) => {
          handleSubmit(
            {
              isPersonNew: false,
              ...values,
            },
            resetForm,
            isUbo
          );
        };

        return (
          <Wrapper>
            <Title>
              <span>Person Search</span>
              <IconButton
                data-test-id="close-persons-table"
                ariaLabel="Close"
                height="40px"
                icon={<StyledClose />}
                onClick={() => {
                  openNewPersonForm();
                  setIsPersonsTableOpen(false);
                }}
                title="Close"
                type="button"
                width="40px"
              />
            </Title>
            <ContentWrapper>
              <Row>
                <div>
                  <Label label="Nationality" />
                  <SelectField
                    name="nationality"
                    placeholder="Not selected"
                    options={nationalities}
                    displayNameSelector={(x) => `${x.name}  (${x.twoLetterCountryCode})`}
                    valueNameSelector={(x) => x.id}
                    handleChange={(name, value) => {
                      const nationality = nationalities.find((n) => n.id === value);
                      const newValue = !!nationality
                        ? {
                            id: nationality.id,
                            displayName: nationality.name,
                            partitionKey: nationality.twoLetterCountryCode,
                          }
                        : null;
                      const nationalityId = !!newValue ? newValue.id : null;
                      setFieldValue(name, newValue);
                      handleNationalityChange(nationalityId);
                    }}
                    handleBlur={setFieldTouched}
                    value={values.nationality?.id || ''}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div>
                  <Label label="First Name" />
                  <InputField
                    name="firstName"
                    value={values.firstName}
                    type="text"
                    placeholder="First Name"
                    handleChange={(e) => {
                      handleChange(e);
                      handleFirstNameChange(e);
                    }}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div>
                  <Label label="Last Name" />
                  <InputField
                    name="lastName"
                    value={values.lastName}
                    type="text"
                    placeholder="Last Name"
                    handleChange={(e) => {
                      handleChange(e);
                      handleLastNameChange(e);
                    }}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div>
                  <Label label="Date of Birth" />
                  <DatePicker
                    name="dateOfBirth"
                    placeholder="Select a date"
                    value={values.dateOfBirth}
                    touched={touched}
                    errors={errors}
                    handleDate={(date) => setDateOfBirth(date)}
                  />
                </div>
                <PepWrapper>
                  <Label label="PEP" />
                  <Pep>
                    <TriStateCheckbox
                      name={`isPoliticallyExposed`}
                      value={values.isPoliticallyExposed}
                      onClick={(newValue) => setFieldValue(`isPoliticallyExposed`, newValue)}
                    />
                  </Pep>
                </PepWrapper>
                <SearchButtonWrapper>
                  <BoxButton
                    aria-label="Search"
                    disabled={!dirty || !shouldSearch || isRoleOpen}
                    onClick={() => handlePersonSearch(setFieldValue)}
                    padding="4px 20px"
                    height="40px"
                    startIcon={<Search />}
                    type="button"
                  >
                    SEARCH
                  </BoxButton>
                </SearchButtonWrapper>
              </Row>
              <NotificationsWrapper>
                {!isSubmitted && isPersonsTableOpen && uniquePersons?.length > 0 ? (
                  <WarningNotification
                    size="small"
                    headingText="Some Persons found in the KYC-portal, please consider using an existing one."
                  />
                ) : !isSubmitted && isRoleOpen ? (
                  <WarningNotification
                    size="small"
                    headingText="No Persons found in the KYC-portal, please add role to create a new person."
                  />
                ) : null}
                {isSubmitted && !dirty && !isError && <ApprovedNotification text="Person role successfully added." />}
                {isLoading && <StyledLoad />}
              </NotificationsWrapper>
              {isRoleOpen && (
                <div>
                  <div>
                    <CreatePerson
                      buttonText="Create Person"
                      selectPerson={createPersonRole}
                      values={values}
                      roleTypes={roleTypes}
                      isSaving={isSaving}
                      errorMessage={errorMessage}
                      isUbo={isUbo}
                    />
                  </div>
                </div>
              )}
              {isPersonsTableOpen && uniquePersons?.length > 0 && (
                <>
                  <ExistingPersonsNotification
                    persons={uniquePersons}
                    selectPerson={addExistingPersonRole}
                    roleTypes={roleTypes}
                    errorMessage={errorMessage}
                    isSaving={isSaving}
                    isUbo={isUbo}
                  />
                  {!values.dateOfBirth && (
                    <CreatePerson
                      buttonText="Create Person"
                      selectPerson={createPersonRole}
                      values={values}
                      roleTypes={roleTypes}
                      errorMessage={errorMessage}
                      isSaving={isSaving}
                      isUbo={isUbo}
                    />
                  )}
                </>
              )}
            </ContentWrapper>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

NewPersonForm.propTypes = {
  onSubmit: PropTypes.func,
  clientId: PropTypes.string,
  etag: PropTypes.string,
};
