import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

const Tab = styled.button<{ isActive: boolean }>`
  border: none;
  background: ${(props) => (props.isActive ? props.theme.marineBlue10 : 'transparent')};
  border-top: 4px solid ${(props) => (props.isActive ? props.theme.marineBlue : 'transparent')};
  color: ${(props) => (props.isActive ? props.theme.marineBlue : props.theme.marineBlue50)};
  font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
  font-size: 14px;
  white-space: nowrap;
  padding: 8px 16px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 4px 4px 0 0;
  text-align: center;
  width: 15%;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
`;

const TabsWrapper = styled.div`
  display: flex;
`;

export type TabDefinition = {
  heading: ReactNode;
  key: string;
}

type ContentTabsProps = {
  tabs: TabDefinition[];
  activeTab: string | undefined;
  setActiveTab: (tabValue: string) => void;
  className?: string;
}

export const ContentTabs = ({ tabs, activeTab, setActiveTab, ...props }: ContentTabsProps) => {

  const tabsWithSetActive = useMemo(
    () => tabs.map(t => ({...t, setIsActive: () => setActiveTab(t.key)})),
    [tabs, setActiveTab]
  );

  return (
    <TabsWrapper {...props}>
      {tabsWithSetActive.map((t) => (
        <Tab
          key={t.key}
          isActive={activeTab === t.key}
          onClick={t.setIsActive}
        >
          {t.heading}
        </Tab>
      ))}
    </TabsWrapper>
  );
};
