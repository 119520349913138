import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate, getFriendlyDateTime } from '../../utils/date';
import { getUser, User } from '../../services/getUser';
import { getAcceptanceLogComments } from "../../utils/acceptanceLogUtils";
import { InsuredObjectAcceptanceLog } from "../../services/useAcceptanceLogs";
import { useInsuredObjectDetails } from "../../services/useInsuredObjectDetails";

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 2fr) repeat(7, minmax(80px, 1fr)) minmax(150px, 2fr);
  grid-gap: 8px;
  align-items: center;
  padding: 16px 8px;
  word-wrap: break-word;
  :nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

type InsuredObject = {
  name: string;
};

const isInsuredObject = (thing: any): thing is InsuredObject => {
  return thing && thing.name && typeof thing.name === 'string';
}

export const LogDataInsuredObject = ({ item } : {item: InsuredObjectAcceptanceLog}) => {
  const [user, setUser] = useState<User>();

  const { data: insuredObject } = useInsuredObjectDetails(item.fleetId, item.insuredObjectId);
  const entityName = isInsuredObject(insuredObject) ? insuredObject.name : undefined;

  useEffect(() => {
    item.userId &&
      getUser(item.userId).then((response) => {
        setUser(response);
      });
  }, [item.userId]);

  return (
    <Grid>
      <span>{entityName}</span>
      <span></span>
      <span></span>
      <span></span>
      <span>{getFriendlyDate(item.acceptancePeriod.from)}</span>
      <span>{getFriendlyDate(item.acceptancePeriod.to)}</span>
      <span>{user?.name}</span>
      <span>{getFriendlyDateTime(item.acceptedAt)}</span>
      <span>{getAcceptanceLogComments(item)}</span>
    </Grid>
  );
};

LogDataInsuredObject.propTypes = {
  item: PropTypes.object,
};
