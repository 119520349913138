import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Label } from './formFields/Label';
import { InfoCircle, Question } from '@instech/icons';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  p {
    color: ${(props) => props.theme.white};
    position: absolute;
    bottom: -20px;
    left: -25px;
    right: -25px;
    padding: 5px 7px;
    z-index: -1;
    font-size: 14px;
    border-radius: 2px;
    background: ${(props) => props.theme.marineBlue};
    border: 1px solid ${(props) => props.theme.marineBlue};
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 999;
    width: fit-content;
    white-space: nowrap;
  }
  p:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${(props) => props.theme.marineBlue};
    position: absolute;
    bottom: -8px;
    left: 28px;
  }
  :hover p {
    bottom: 10px;
    right: 30px;
    visibility: visible;
    opacity: 1;
    width: fit-content;
    white-space: nowrap;
  }
`;
const StyledInfoCircle = styled(InfoCircle)`
  height: 14px;
  justify-self: right;
  :hover {
    cursor: pointer;
  }
`;
const StyledQuestion = styled(Question)`
  margin-right: 2px;
`;
export const LabelWithIcon = ({ labelText, infoText, required, infoIcon, questionIcon }) => {
  return (
    <Wrapper>
      <Label label={labelText} required={required} />
      <p>{infoText}</p>
      {infoIcon && <StyledInfoCircle />}
      {questionIcon && <StyledQuestion />}
    </Wrapper>
  );
};

LabelWithIcon.propTypes = {
  labelText: PropTypes.string,
  infoText: PropTypes.string,
  required: PropTypes.bool,
  infoIcon: PropTypes.bool,
  questionIcon: PropTypes.bool,
};
