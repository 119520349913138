import styled from 'styled-components';

export const FleetStatusTag = styled.div<{ highlight?: string }>`
  background: ${(props) => props.highlight};
  color: ${(props) => (props.theme.marineBlue)};
  font-size: 14px;
  white-space: nowrap;
  padding: 8px 16px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-left: 32px;
`;
