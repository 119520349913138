import React from 'react';
import styled from 'styled-components';
import { BoxButton, ButtonGroup } from '@instech/components';
import { Load } from '@instech/icons';
import { ErrorMessage } from 'components/shared/datePicker/Components';
import { useActionsForQuotedFleetsContext } from './ActionsForQuotedFleetsContext';

const StyledLoad = styled(Load)`
  height: 20px;
  margin: 32px 32px 32px auto;
`;

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 30%;
  right: 30%;
  top: 30%;
  height: fit-content;
  margin: auto;
  background: white;
  padding: 0;
`;
const FormWrapper = styled.div`
  padding: 0 16px 16px 16px;
  color: ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.white};
  text-align: center;
  > * {
    margin: 32px 0;
  }
`;
const Text = styled.div`
  font-size: 18px;
`;
const Title = styled.div``;
const FleetName = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
const AdditionalText = styled.div``;
const Button = styled.button`
  align-self: flex-end;
  margin: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
  color: ${(props) => props.theme.marineBlue};
  font-size: 24px;
  line-height: 24px;
  outline: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  transition: all 0.2s ease;

  :hover {
    transform: scale(1.3);
    cursor: pointer;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ModalHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.whiteBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  text-transform: uppercase;
`;

export const QuotedFleetModalDialog = () => {
  const {
    fleetName,
    insuranceYear,
    isRemoveDialogVisible,
    setIsRemoveDialogVisible,
    isAcceptDialogVisible,
    setIsAcceptDialogVisible,
    isRefreshDialogVisible,
    setIsRefreshDialogVisible,
    isSubmitting,
    isError,
    removeQuotedFleetEntry,
  } = useActionsForQuotedFleetsContext();

  const title = isRemoveDialogVisible ? 'Remove The Fleet' : 'Accept The Fleet';
  const additionalText = '(remove the entry from the KYC portal)';
  const confirmButtonText = isRemoveDialogVisible ? 'Remove' : 'Accept';
  const cancelButtonText = 'Cancel';

  const onCancel = () => {
    isAcceptDialogVisible && setIsAcceptDialogVisible(false);
    isRemoveDialogVisible && setIsRemoveDialogVisible(false);
    isRefreshDialogVisible && setIsRefreshDialogVisible(false);
  };

  const onConfirm = () => {
    (isRemoveDialogVisible || isAcceptDialogVisible) && removeQuotedFleetEntry();
  };

  return (
    <Wrapper>
      <Dialog>
        <ModalHeader>
          <Title>{title}</Title>
          <Button onClick={onCancel}>x</Button>
        </ModalHeader>

        <FormWrapper>
          <Text>{title}</Text>
          <FleetName>
            {fleetName} ({insuranceYear})
          </FleetName>
          <AdditionalText>{additionalText}</AdditionalText>
          {isSubmitting && <StyledLoad />}
          {isError && (
            <ErrorMessage>
              <div>Failed to {confirmButtonText} Fleet Entry.</div>
            </ErrorMessage>
          )}
          {!isSubmitting && (
            <ButtonsWrapper>
              <ButtonGroup buttonGap='16px'>
                {onCancel && cancelButtonText && (
                  <BoxButton inverted aria-label='Cancel changes' onClick={onCancel} type='button'>
                    {cancelButtonText.toUpperCase()}
                  </BoxButton>
                )}
                {confirmButtonText && (
                  <BoxButton aria-label='Confirm' onClick={onConfirm} type='button'>
                    {confirmButtonText.toUpperCase()}
                  </BoxButton>
                )}
              </ButtonGroup>
            </ButtonsWrapper>
          )}
        </FormWrapper>
      </Dialog>
    </Wrapper>
  );
};
