import { useEffect } from 'react';
import { useAccount } from '../providers/authenticationProvider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getApplicationInsights } from './getConfiguration';

export const initAppInsights = () => {
  const { isAuthenticated, user } = useAccount();

  useEffect(() => {
    const fetchAppInsights = async () => {
      try {
        const config = await getApplicationInsights();

        if (!config?.instrumentationKey)
        {
          console.log('Application Insights instrumentation key is missing. Instrumentation is disabled.');
          return;
        }

        const appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: config?.instrumentationKey ?? '',
            maxBatchInterval: 30000,
            accountId: user.email,
            enableAutoRouteTracking: true
          }
        });
        appInsights.loadAppInsights();

        const telemetryInitializer = envelope => {
          envelope.tags["ai.cloud.role"] = "Insify.Kyc.Web";
        };
        appInsights.addTelemetryInitializer(telemetryInitializer);

        window.appInsights = appInsights;
      } catch (error) {
        // Fail silently
      }
    };

    if (isAuthenticated) {
      fetchAppInsights();
    }
  }, [isAuthenticated]);
};

// How to use AppInsights anywhere in app:
//import { getAppInsights } from './services/useAppInsights';
//import {SeverityLevel} from '@microsoft/applicationinsights-web';
//
//var logger = getAppInsights();
//logger?.trackTrace({ message: 'Application Insights initialized from KYC portal.', severityLevel: SeverityLevel.Information})
export const getAppInsights = () => {
  return window.appInsights;
}
