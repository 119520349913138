import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trash, Pen, Eye } from '@instech/icons';
import { useAppContext } from "../appRouting/AppContext";

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.marineBlue};
  :last-child {
    border-bottom: none;
  }
`;
const Button = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  color: ${(props) => props.theme.marineBlue};
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  transition: color 1s ease-out;
  :hover {
    background-color: ${(props) => props.theme.marineBlue10};
  }
`;
const StyledTrash = styled(Trash)`
  color: ${(props) => props.theme.lightRed};
  transition: color 1s ease-out;
  margin: 4px;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.red};
  }
`;
const StyledEdit = styled(Pen)`
    color: ${(props) => props.theme.marineBlue50};
    transition: color 1s ease-out;
    margin: 4px;
    :hover {
        cursor: pointer;
        color: ${(props) => props.theme.marineBlue};
    }
`;
const StyledEye = styled(Eye)`
  color: ${(props) => props.theme.green75};
  transition: color 1s ease-out;
  margin: 4px;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.marineBlue};
  }
`;

export const ButtonsSection = ({
  handleRemove,
  personId,
  fleetId,
  clientId,
  vesselId,
  remove,
  index,
  locationState,
  isUbo,
  isPep,
  isHRAccepted,
  hasPendingAcceptanceReview,
  showAcceptanceModal
}) => {
  const { isReviewer } = useAppContext();

  const editPersonPath = !fleetId
    ? `/persons/${clientId}/${personId}/edit`
    : !vesselId
    ? `/persons/${fleetId}/${clientId}/${personId}/edit`
    : !clientId
    ? `/ubo/persons/${fleetId}/${vesselId}/${personId}/edit`
    : `/persons/${fleetId}/${vesselId}/${clientId}/${personId}/edit`;
  return (
    <Wrapper>
      <Link
        data-test-id="link-to-person-data"
        to={editPersonPath}
        state={{
          ...locationState,
          isUbo,
        }}
        style={{ textDecoration: 'none' }}
      >
        <Button data-test-id="edit-person" type="button">
          <StyledEdit />
          <span>Edit</span>
        </Button>
      </Link>
      <Button
        type="button"
        data-test-id="remove-person"
        onClick={() => handleRemove(index, remove, personId, clientId)}
      >
        <StyledTrash /> <span>Remove</span>
      </Button>
      {isPep && !isHRAccepted && !hasPendingAcceptanceReview && (
        <Button
          type="button"
          onClick={() => showAcceptanceModal()}
        >
          <StyledEye />
          <span>{'Propose HR acceptance'}</span>
        </Button>
      )}
      {hasPendingAcceptanceReview && isReviewer && (
        <Button
          type="button"
          onClick={() => showAcceptanceModal()}
        >
          <StyledEye />
          <span>{'Review HR acceptance'}</span>
        </Button>
      )}
    </Wrapper>
  );
};

ButtonsSection.propTypes = {
  handleRemove: PropTypes.func,
  clientId: PropTypes.string,
  personId: PropTypes.string,
  remove: PropTypes.func,
  index: PropTypes.number,
  personName: PropTypes.string,
  locationState: PropTypes.object,
  isPep: PropTypes.bool,
  isHRAccepted: PropTypes.bool,
  hasPendingAcceptanceReview: PropTypes.bool,
  showAcceptanceModal: PropTypes.func
};
