import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LeftArrow, Close, Load, ArrowForward, ArrowBack } from '@instech/icons';
import { useInsuredObjectsFullList } from '../../services/useInsuredObjects';
import { useExistingOwnersForObjects, useOwnersFromLLIforObjects } from '../../services/useOwnersForObjects';
import { ImportCompany } from '../../services/postPutCompanyData';
import { AddCompanyRolesToObjects } from '../../services/postPutCompanyData';
import { useOrganizationRoleTypes } from '../../services/UseOrganizationRoleTypes';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';
import { SimilarCompanies } from './SimilarCompanies';
import { SelectCompanies } from './SelectCompanies';
import { ApproveTransfer } from './ApproveTransfer';
import { BoxButton } from '@instech/components';
import { useFleetOverviewContext } from '../fleetDetails/FleetOverviewContext';
import { WizardHeaderInfo } from './WizardHeaderInfo';
import { ParseValidationFlags } from '../../utils/validations/functions';

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
`;
const ErrorMessage = styled.div`
  color: red;
  text-align: right;
`;

const ComponentWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.marineBlue};
  padding: 16px;
  margin: 64px;
`;
const Content = styled.div`
  margin: 32px 16px 16px 16px;
`;
const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
`;
const BackHomeLink = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const StyledClose = styled(Close)`
  :hover {
    transform: scale(1.3);
  }
`;
const Title = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.marineBlue};
  margin: 32px;
  text-align: center;
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 16px 0;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.stepOne ? 'right' : 'space-between')};
  margin-top: 36px;
  padding: 20px 0;
`;
const ApproveButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;
const beneficialRoleName = 'Beneficial Owner';
const registeredRoleName = 'Registered Owner';

const getOwnersFromLLIByRoleType = (ownersFromLLI, existingOwners, insuredObjects, roleTypes) => {
  let result = {
    beneficialOwners: [],
    registeredOwners: [],
  };

  for (const ownership of ownersFromLLI) {
    let vessel = insuredObjects && insuredObjects.find((v) => v.imo === ownership.imo);
    if (!!vessel) {
      const organizationId = ownership.kycOrganization ? ownership.kycOrganization.id : ownership.company.id;
      if (
        !existingOwners[vessel.id]?.some(
          (e) =>
            e.imo === ownership.imo &&
            e.organization.id === organizationId &&
            e.roleType.roleName === ownership.roleType.roleName
        )
      ) {
        const roleName = ownership.roleType.roleName;

        const owner = {
          organizationId,
          name: ownership.company.displayName,
          objectId: vessel.id,
          activePeriod: ownership.period,
          roleType: {},
          isOwnerAssured: false,
          objectName: vessel.name,
          validations: ParseValidationFlags(vessel.validations),
          country: ownership.kycOrganization
            ? ownership.kycOrganization.displayAddress
            : ownership.company.displayAddress,
          kycOrganization: ownership.kycOrganization,
          similarKycOrganizations: ownership.similarKycOrganizations,
        };

        if (roleName === beneficialRoleName) {
          owner.roleType = roleTypes.beneficial;
          result.beneficialOwners.push(owner);
        } else if (roleName === registeredRoleName) {
          owner.roleType = roleTypes.registered;
          result.registeredOwners.push(owner);
        }
      }
    }
  }
  return result;
};
//group companies to show only one company for all objects on similar companies step
const groupedCompanies = (companies) => {
  const result = [];
  companies.forEach((c) => {
    const index = result.findIndex((r) => r.organizationId === c.organizationId);
    if (index >= 0) {
      result[index].objectsRoles.push({
        objectId: c.objectId,
        objectName: c.objectName,
        roleType: c.roleType,
        activePeriod: c.activePeriod,
      });
    } else {
      c.objectsRoles = [
        {
          objectId: c.objectId,
          objectName: c.objectName,
          roleType: c.roleType,
          activePeriod: c.activePeriod,
        },
      ];
      result.push(c);
    }
  });
  return result;
};

export const BeneficialOwnerWizard = () => {
  const {
    fleetId,
    fleetName,
    insuranceYear,
    handleBeneficialOwnerWizardOpen,
    sortOrder,
    fieldsSortOrder,
    mutateWizardValidationTasks,
  } = useFleetOverviewContext();

  const insuredObjects = useInsuredObjectsFullList(fleetId, sortOrder, fieldsSortOrder);
  const ownersFromLLI = useOwnersFromLLIforObjects(fleetId);
  const existingOwners = useExistingOwnersForObjects(fleetId);
  const [checkedCompanies, setCheckedCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isImportError, setIsImportError] = useState(false);
  const [isImportErrorMessage, setIsImportErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [checkedPages, setCheckedPages] = useState([]);
  const [savedSelectedCompanies, setSavedSelectedCompanies] = useState([]);

  const organizationRoleTypes = useOrganizationRoleTypes();
  const roleTypes = {
    beneficial: organizationRoleTypes?.find((r) => r.roleName === beneficialRoleName && r.isCoAssured === false),
    registered: organizationRoleTypes?.find((r) => r.roleName === registeredRoleName && r.isCoAssured === false),
  };

  const separatedFromLLIOwners =
    ownersFromLLI &&
    roleTypes &&
    insuredObjects &&
    getOwnersFromLLIByRoleType(ownersFromLLI, existingOwners, insuredObjects, roleTypes);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setIsError(false);
    setIsImportError(false);
    let submittingValues = {
      fleetId,
      data: selectedCompanies,
      subject: {
        id: fleetId,
        partitionKey: fleetId,
        entityType: 'Fleet',
      },
    };

    let uniqueCompaniesForImport = [
      ...new Map(submittingValues.data.map((item) => [item.organizationId, item])).values(),
    ];
    for (let index = 0; index < uniqueCompaniesForImport.length; index++) {
      const roleData = uniqueCompaniesForImport[index];
      if (roleData.kycOrganization === null) {
        try {
          const response = await ImportCompany({
            sourceId: roleData.organizationId,
            sourceSystem: 'Lli',
            twoLetterCountryCode: '',
          });
          uniqueCompaniesForImport[index].organizationId = response.id;
        } catch (error) {
          console.log(error);
          setIsImportError(true);
          setIsImportErrorMessage(`Failed to add company ${uniqueCompaniesForImport.map((el) => el.name)}`);
          //remove from unique companies array failed to import companies
          uniqueCompaniesForImport = uniqueCompaniesForImport.filter(
            (d) => d.organizationId !== roleData.organizationId
          );
          --index;
        }
      } else {
        uniqueCompaniesForImport[index].organizationId = roleData.kycOrganization?.id || roleData.organizationId;
      }
    }
    try {
      const companiesForAddOwnersToObjects = submittingValues.data
        .filter((d) => uniqueCompaniesForImport.some((u) => u.organizationId === d.organizationId)) //keep in data only successfully imported companies
        .map((company) => {
          const responseCompanyData = uniqueCompaniesForImport.find((c) => c.organizationId === company.organizationId);
          company.twoLetterCountryCode = responseCompanyData.twoLetterCountryCode;
          company.mdmReference = {
            mdmExternalId: responseCompanyData.id,
            mdmSourceEntityType: 'vesselownership',
            sourceSystem: 'LLI',
          };
          return company;
        });
      const dataForAddOwnersToObjects = { ...submittingValues, data: companiesForAddOwnersToObjects };
      await AddCompanyRolesToObjects(dataForAddOwnersToObjects);
    } catch (error) {
      console.log('post error', error);
      setIsSubmitting(false);
      setIsError(true);
      setErrorMessage(formatErrorMessage(error));
      return;
    }
    setIsSubmitting(false);
    setIsSubmitted(true);
    mutateWizardValidationTasks();
  };

  const handleSelectAll = (target, roleName) => {
    if (target.checked) {
      const checkedOwners =
        roleName === beneficialRoleName
          ? separatedFromLLIOwners.beneficialOwners
          : separatedFromLLIOwners.registeredOwners;
      const selectedOwners = checkedOwners.filter(
        (c) => !c.similarKycOrganizations || c.similarKycOrganizations.length === 0
      );
      setSelectedCompanies((prev) => [...selectedOwners, ...prev]);
      setCheckedCompanies((prev) => [...checkedOwners, ...prev]);
    } else {
      setSelectedCompanies((prev) => prev.filter((p) => p.roleType.roleName !== roleName));
      setCheckedCompanies((prev) => prev.filter((p) => p.roleType.roleName !== roleName));
    }
  };
  const titleText =
    currentStep === 2
      ? 'SIMILAR COMPANIES EXIST IN THE KYC-PORTAL'
      : "ADD OWNERS FROM Lloyd's List Intelligence TO MULTIPLE OBJECTS";

  const subtitleText =
    currentStep === 1
      ? 'Select owners to transfer from LLI'
      : currentStep === 2
      ? 'Please consider using one of the existing companies instead of transferring a new one from LLI'
      : 'Overview and approve transfer';

  useEffect(() => {
    insuredObjects && existingOwners && ownersFromLLI && setIsLoading(false);
  }, [insuredObjects, existingOwners, ownersFromLLI]);

  const checkedCompaniesWithSimilar = checkedCompanies.filter((c) => c.similarKycOrganizations?.length > 0);
  const isSimilarCompaniesExist = checkedCompaniesWithSimilar && checkedCompaniesWithSimilar.length > 0;
  const groupedCompaniesWithSimilar = groupedCompanies(checkedCompaniesWithSimilar);

  const handleChangeSimilar = (e, company) => {
    const name = e.target.name;
    //create companies array to add it to selectedCompanies
    let companies;
    //if lli company
    if (company.objectsRoles && company.organizationId === name) {
      companies =
        company.objectsRoles.length > 1
          ? company.objectsRoles.map((o) => ({
              ...company,
              objectId: o.objectId,
              objectName: o.objectName,
              roleType: o.roleType,
              activePeriod: o.activePeriod,
            }))
          : [company];
    } else {
      // select from groupedCompaniesWithSimilar array mainCompany with data about objects and roles
      const mainCompany = groupedCompaniesWithSimilar.find((g) => g.organizationId === company.mainCompany);
      companies =
        mainCompany.objectsRoles.length > 1
          ? mainCompany.objectsRoles.map((o) => ({
              ...company,
              objectId: o.objectId,
              objectName: o.objectName,
              roleType: o.roleType,
              activePeriod: o.activePeriod,
            }))
          : [company];
    }
    setSelectedCompanies((prev) => [
      ...prev.filter((p) => p.mainCompany !== name && p.organizationId !== name),
      ...companies,
    ]);
  };

  const handleClickBack = () => {
    if (isSimilarCompaniesExist) {
      currentStep === 3 && setSelectedCompanies(savedSelectedCompanies);
      setCurrentStep(currentStep - 1);
    } else {
      setSelectedCompanies(savedSelectedCompanies);
      setCurrentStep(1);
    }
    setIsError(false);
    setErrorMessage(null);
  };
  const handleClickNext = () => {
    if (isSimilarCompaniesExist) {
      currentStep === 2 && setSavedSelectedCompanies(selectedCompanies);
      setCurrentStep(currentStep + 1);
    } else {
      setSavedSelectedCompanies(selectedCompanies);
      setCurrentStep(3);
    }
  };

  const [unselectAllIds, setUnselectAllIds] = useState([]);

  const handleUnselectAll = (id, currentPage) => {
    setUnselectAllIds((prev) => [...prev.filter((p) => p !== id), id]);
    setSelectedCompanies((prev) => prev.filter((p) => p.mainCompany !== id && p.organizationId !== id));
    setCheckedPages((prev) => prev.filter((p) => p !== currentPage));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const companyIndex = currentPage - 1;

  const objectNames = [
    ...new Set(groupedCompaniesWithSimilar[companyIndex]?.objectsRoles.map((o) => o.objectName)),
  ].join(', ');
  const roleNames = [
    ...new Set(groupedCompaniesWithSimilar[companyIndex]?.objectsRoles.map((o) => o.roleType.roleName)),
  ].join();
  return (
    <ComponentWrapper>
      <ContentWrapper>
        <Navigation>
          <BackHomeLink data-test-id="link-back-home" onClick={handleBeneficialOwnerWizardOpen}>
            <LeftArrow /> <span>Back to fleet overview</span>
          </BackHomeLink>
          <StyledClose data-test-id="close-bo-wizard" onClick={handleBeneficialOwnerWizardOpen} />
        </Navigation>
        <Title>{titleText}</Title>
        <SubTitle>{subtitleText}</SubTitle>
        <WizardHeaderInfo
          fleetName={fleetName}
          insuranceYear={insuranceYear}
          isObjectName={currentStep > 1 && objectNames.length > 0}
          objectName={objectNames}
          roleName={roleNames}
          isSimilarCompanyStep={currentStep === 2}
        />
        <Content>
          {currentStep === 1 && (
            <SelectCompanies
              ownersFromLLI={ownersFromLLI}
              checkedCompanies={checkedCompanies}
              selectedCompanies={selectedCompanies}
              separatedFromLLIOwners={separatedFromLLIOwners}
              handleSelectAll={handleSelectAll}
              beneficialRoleName={beneficialRoleName}
              registeredRoleName={registeredRoleName}
              insuredObjects={insuredObjects}
              existingOwners={existingOwners}
              setCheckedCompanies={setCheckedCompanies}
              setSelectedCompanies={setSelectedCompanies}
              roleTypes={roleTypes}
              isLoading={isLoading}
            />
          )}
          {currentStep === 2 && isSimilarCompaniesExist && (
            <SimilarCompanies
              selectedCompanies={selectedCompanies}
              handleChange={handleChangeSimilar}
              checkedPages={checkedPages}
              setCheckedPages={setCheckedPages}
              groupedCompaniesWithSimilar={groupedCompaniesWithSimilar}
              unselectAllIds={unselectAllIds}
              handleUnselectAll={handleUnselectAll}
              companyIndex={companyIndex}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          {currentStep === 3 && (
            <ApproveTransfer
              selectedCompanies={selectedCompanies}
              isSubmitting={isSubmitting}
              isSubmitted={isSubmitted}
              handleClose={handleBeneficialOwnerWizardOpen}
              handleSubmit={handleSubmit}
              setIsError={setIsError}
              groupedCompaniesWithSimilar={groupedCompaniesWithSimilar}
            />
          )}
          <ButtonsWrapper>
            {currentStep > 1 && !isSubmitted ? (
              <BoxButton
                data-test-id="go-back"
                aria-label="Back"
                inverted
                onClick={handleClickBack}
                padding="10px 20px"
                startIcon={<ArrowBack />}
                type="button"
              >
                BACK
              </BoxButton>
            ) : (
              <div></div>
            )}
            {currentStep === 3 ? (
              <ApproveButtonWrapper>
                {isSubmitting ? (
                  <StyledLoad />
                ) : isSubmitted ? (
                  <BoxButton
                    data-test-id="close-button"
                    aria-label="Close"
                    inverted
                    onClick={handleBeneficialOwnerWizardOpen}
                    padding="10px 20px"
                    type="button"
                  >
                    CLOSE
                  </BoxButton>
                ) : (
                  <BoxButton
                    data-test-id="approve-button"
                    aria-label="Approve"
                    disabled={selectedCompanies.length === 0 || isSubmitted}
                    onClick={() => handleSubmit()}
                    padding="10px 20px"
                    type="button"
                  >
                    APPROVE
                  </BoxButton>
                )}
              </ApproveButtonWrapper>
            ) : (
              <BoxButton
                data-test-id="go-forward"
                aria-label="Next"
                disabled={checkedCompanies.length === 0}
                onClick={handleClickNext}
                padding="10px 20px"
                endIcon={<ArrowForward />}
                type="button"
              >
                NEXT
              </BoxButton>
            )}
          </ButtonsWrapper>

          {isError && (
            <ErrorMessage>
              <div>Failed to save data.</div>
              <div>{errorMessage}</div>
            </ErrorMessage>
          )}
          {isImportError && <ErrorMessage>{isImportErrorMessage}</ErrorMessage>}
        </Content>
      </ContentWrapper>
    </ComponentWrapper>
  );
};
