import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate } from '../../utils/date';
import { WarningNotification } from '@instech/components';
import { ApprovedNotification } from '../shared/ApprovedNotification';

const OwnersListWrapper = styled.div`
  margin: 32px 32px 64px 32px;
`;
const PersonsTable = styled.div`
  font-size: 14px;
  margin: 32px 0;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.marineBlue};
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.flatWhite};

  font-weight: bold;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  :nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const NotSelectedCompaniesWrapper = styled.div`
  background: #fff0be;
  margin-top: -26px;
  border-left: 6px solid ${(props) => props.theme.yellow};
  padding: 0 0 16px 24px;
`;

export const TransferOwnersList = ({ selectedOwners, isSubmitted, groupedOwnersWithSimilar }) => {
  const notSelectedCompanies = groupedOwnersWithSimilar.filter(
    (g) =>
      !selectedOwners.some((s) => s.id === g.id) &&
      g.similarKycOrganizations.every((similar) => !selectedOwners.some((s) => s.id === similar.id))
  );

  return (
    <OwnersListWrapper>
      <PersonsTable>
        <TableHeader>
          <div>Role</div>
          <div>Role Period</div>
          <div>Company Name</div>
          <div>Address</div>
          <div>LEI</div>
        </TableHeader>
        {selectedOwners.map((item, index) => {
          return (
            <TableRow key={index}>
              <div>{item.roleType.roleName}</div>
              <div>
                {item.period.from && getFriendlyDate(item.period.from)}
                &nbsp; - &nbsp;
                {item.period.to && getFriendlyDate(item.period.to)}
              </div>
              <div>{item.kycOrganization != null ? item.kycOrganization.displayName : item.company.displayName}</div>
              <div>
                {item.kycOrganization != null ? item.kycOrganization.displayAddress : item.company.displayAddress}
              </div>
              <div>{item.company.lei}</div>
            </TableRow>
          );
        })}
      </PersonsTable>
      {notSelectedCompanies.length === 0 && !isSubmitted ? (
        <WarningNotification
          size='small'
          headingText='Approve the transfer to make owner role be a part of the KYC-portal.'
        />
      ) : (
        !isSubmitted && (
          <WarningNotification
            size='small'
            headingText='One or more companies were not selected a version of to be used in KYC. The following companies will not be imported to the objects on the fleet:'
          />
        )
      )}
      {isSubmitted && (
        <ApprovedNotification text='Transferred successfully. It will take some time to update object in the system.' />
      )}
      {notSelectedCompanies.length > 0 && !isSubmitted && (
        <NotSelectedCompaniesWrapper>
          {notSelectedCompanies.map((el, index) => {
            return (
              <span key={index}>
                {el.company.displayName?.trim()}
                {(index !== notSelectedCompanies.length - 1 || !notSelectedCompanies.length === 1) && ', '}
              </span>
            );
          })}
        </NotSelectedCompaniesWrapper>
      )}
    </OwnersListWrapper>
  );
};

TransferOwnersList.propTypes = {
  selectedOwners: PropTypes.array,
  groupedOwnersWithSimilar: PropTypes.array,
  isSubmitted: PropTypes.bool,
};
